import { orderStatuses } from '@actr/specs';
import styled from 'styled-components';

export const Body = styled.div<{
  orderStatus: orderStatuses;
}>(({ theme }) => ({
  backgroundColor: theme.colors.background.base.light.default,
  borderRadius: theme.borderRadius.m,
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.12)'
}));

export const Wrapper = styled.div(({ theme }) => ({
  padding: theme.padding.m,
}));

export const ContentWrapper = styled.div({});
