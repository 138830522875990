import * as UI from '@actr/ui';
import { ButtonProps, ButtonTypes } from '@actr/ui';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React, { ComponentType, FC, useState } from 'react';

import { AccountAviaOrderSectionProps } from '../../avia/specs';
import * as Styled from './style';

export interface Props {
  Button?: ComponentType<Omit<ButtonProps, 'theme'>>;
}

const CertificatesDownloadButton: FC<AccountAviaOrderSectionProps & Props> = props => {
  const { order: orderStore, Button, dataId } = props;
  const { t } = useOrdersTranslator();
  const [isOpen, setIsOpen] = useState(false);

  // TODO в обычных заказах используется старая модель CertificateModel, в жд и отелях скорее всего url в сертификате не будет
  const order = orderStore.aviaOrder;

  const dataIdName = `${dataId}_certificates.`;

  if (!order!.certificates.length) {
    return null;
  }

  function renderButton() {
    const ButtonRender = Button || Styled.Button;

    return (
      <ButtonRender
        dataId={`${dataIdName}.download-modal-button`}
        buttonType={ButtonTypes.SECONDARY}
        onClick={() => setIsOpen(true)}>
        {t('Получить справку')}
      </ButtonRender>
    );
  }

  return (
    <>
      {renderButton()}
      <UI.Modal
        dataId={`${dataIdName}.modal`}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        backdropCloseModal={true}
        Title={t('Справки')}>
        <Styled.Wrapper>
          {order!.certificates.map(certificate => (
            <Styled.Link
              dataId={`${dataIdName}.download-button`}
              openInNewTab={true}
              href={certificate.url}>
              {certificate.title}
            </Styled.Link>
          ))}
        </Styled.Wrapper>
      </UI.Modal>
    </>
  );
};

export default observer(CertificatesDownloadButton);
