import { useInject } from '@actr/di';
import { orderStatuses, orderTypes } from '@actr/specs';
import { AccountAviaOrderModel, AppStore, AuthStore } from '@actr/stores';
import { ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { ButtonSizes, ButtonTypes, TagSizes, TagTypes } from '@actr/ui';
import { translate } from '@actr/utilities';
import { AkV2Routes, NavLink } from '@actr/widgets';
import { ACCOUNT, HEADER } from 'Components/_misc/specs';
import useOrdersTranslator from 'Components/account/main-pages/v2orders/locales';
import Configs from 'Configs';
import last from 'lodash/last';
import aviaOrderLog, { AviaOrderLogs } from 'Logs/avia/order';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { ReactNode } from 'react';
import WebviewRoutes from 'Root/router/webview/routes';
import ProjectUtils from 'Root/utils/project';

import { AccountOrderCardProps } from '../specs';
import * as Styled from './style';

const Header: React.FC<AccountOrderCardProps> = observer(props => {
  const {
    order,
    orderCheckoutPath,
    useDefaultLink = false,
    isUpdating,
  } = props;
  const auth = useInject(AuthStore);
  const app = useInject(AppStore);
  const { deviceSize } = app;
  const { t } = useOrdersTranslator();

  const dataIdName = `${ACCOUNT}-${order.orderType}-order.${HEADER}`;

  const showButtonToAviaPostsale = order.orderType === orderTypes.AVIA
    && [orderStatuses.TICKET, orderStatuses.PARTIALLY_TICKETED].includes(order.orderStatus)
    && ProjectUtils.isAkRedesign() && Configs.avia.enablePostSale;

  const currentOrder = order.aviaOrder as AccountAviaOrderModel;

  // RENDERERS
  function renderCreateDate() {
    const dateFormat = 'D MMMM YYYY, dd';
    const date = currentOrder.created;
    return moment(date, Configs.dateTime.serverDateTimeFormat).format(dateFormat).toLowerCase();
  }

  function renderOrderLink() {
    if (ProjectUtils.isSber() || ProjectUtils.isWOWTickets()) {
      return null;
    }

    if (orderCheckoutPath && !ProjectUtils.isSber() && !ProjectUtils.isRshb() && (
      showButtonToAviaPostsale || [order.orderStatus].includes(orderStatuses.BOOKED)
    )) {
      const buttonType = showButtonToAviaPostsale ? ButtonTypes.PRIMARY : ButtonTypes.BRAND;

      return renderLink((
        <Styled.PayButton
          dataId={`${dataIdName}.pay-button`}
          deviceSize={deviceSize}
          size={ButtonSizes.S}
          buttonType={isUpdating ? ButtonTypes.SECONDARY : buttonType}
          disabled={isUpdating}>
          { showButtonToAviaPostsale ? t('Добавить услугу') : t('Оплатить') }
        </Styled.PayButton>
      ));
    }

    // TODO Доплата пост сейл продуктов - будет реализовано позже
    /* if (showSurcharge) {
      return (
        <Styled.PayButton
          dataId={`${dataIdName}.surcharge`}
          deviceSize={deviceSize}
          size={ButtonSizes.S}
          buttonType={ButtonTypes.BRAND}
          disabled={isUpdating}
          onClick={handlePay}>
          { t('Доплатить') }
        </Styled.PayButton>
      );
    } */

    return null;
  }

  function renderLink(children: ReactNode) {
    if (ProjectUtils.isWebview()) {
      if (order.orderType !== orderTypes.AVIA || order.orderStatus !== orderStatuses.BOOKED) {
        return null;
      }

      return (
        <NavLink
          route={WebviewRoutes.AVIA_ORDER}
          searchParams={{
            sig: order.orderInfo.sig,
            billingNumber: order.orderInfo.billing_number.toString(),
            phone: auth.user?.phone,
          }}
          useRouter={false}>
          {children}
        </NavLink>
      );
    }

    if (showButtonToAviaPostsale) {
      return (
        <NavLink
          route={AkV2Routes.AVIA_POST_SALES}
          useRouter={false}
          onClick={() => {
            aviaOrderLog.send(AviaOrderLogs.GO_TO_AVIA_POSTSALES, {
              billingNumber: order.orderInfo.billing_number,
            });
            return true;
          }}
          routeParams={{
            bookId: `${order.orderInfo.billing_number}~${order.orderInfo.sig}`,
          }}
          style={{ textDecoration: 'none' }}>
          {children}
        </NavLink>
      );
    }

    return (
      <NavLink
        route={orderCheckoutPath}
        useRouter={!useDefaultLink}
        style={{ textDecoration: 'none' }}>
        {children}
      </NavLink>
    );
  }

  function renderStatusTag() {
    const status = order.orderStatus.toLowerCase() as orderStatuses;
    let colorScheme:ThemeColorValueTypes;

    switch (status) {
      case orderStatuses.BOOKED:
      case orderStatuses.PARTIALLY_TICKETED:
      case orderStatuses.AWAIT_PAYMENT:
        colorScheme = ThemeColorValueTypes.WARNING;
        break;
      case orderStatuses.TICKET:
        colorScheme = ThemeColorValueTypes.SUCCESS;
        break;
      case orderStatuses.PAID:
        colorScheme = ThemeColorValueTypes.INFO;
        break;
      case orderStatuses.CANCELLED:
        colorScheme = ThemeColorValueTypes.BASE;
        break;
      case orderStatuses.REFUNDED:
        colorScheme = ThemeColorValueTypes.CONTRAST;
        break;
      default:
        colorScheme = ThemeColorValueTypes.MUTE;
    }

    return (
      <Styled.OrderStatusTag dataId={`${dataIdName}.status`} size={TagSizes.XS} tagType={TagTypes.TERTIARY} themeColor={colorScheme}>
        {translate.orderStatus(order.orderStatus.toLowerCase() as orderStatuses).toUpperCase()}
      </Styled.OrderStatusTag>
    );
  }

  function renderSurchargeTag() {
    if (!currentOrder.surchargeOrders?.length) {
      return null;
    }

    return (
      <Styled.OrderStatusTag
        dataId={`${dataIdName}.surcharge-status-tag`}
        size={TagSizes.XS}
        tagType={TagTypes.TERTIARY}
        themeColor={ThemeColorValueTypes.WARNING}>
        { t('Есть доплата').toUpperCase() }
      </Styled.OrderStatusTag>
    );
  }

  function renderOrderDate() {
    return (
      <Styled.OrderDate font="paragraph.m" color={ThemeColorValueImportance.LIGHTER}>
        <span data-id={`${dataIdName}.create-date`}>{ renderCreateDate() }</span>
      </Styled.OrderDate>
    );
  }

  function renderOrderNumber() {
    return (
      <Styled.OrderNumber font="paragraph.m">
        { t('Заказ') }
        { ' ' }
        <span data-id={`${dataIdName}.billing-number`}>
          { order.orderInfo.billing_number }
        </span>
      </Styled.OrderNumber>
    );
  }

  function destionationIntermediates() {
    const { segments, segments_direction } = currentOrder.flight;
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    const isRoundTrip = segments_direction.length === 2 && firstSegment.departure.city.code === lastSegment.arrival.city.code;
    const isMultiwayTrip = segments_direction.length > 2;

    if (isRoundTrip) {
      const destination = segments[last(segments_direction[0]) as number];
      return `${destination.arrival.city.title} – `;
    }

    if (isMultiwayTrip) {
      const transfersSegmentDirection = segments_direction.slice(0, -1);
      const transferPoints = transfersSegmentDirection.map(segmentsGroup => {
        const midDestionation = segments[last(segmentsGroup) as number];
        return `${midDestionation.arrival.city.title} – `;
      });

      return transferPoints.join('');
    }

    return '';
  }

  function renderTitle() {
    const { segments } = currentOrder.flight;
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    const cityDep = firstSegment.departure.city.title;
    const cityArr = lastSegment.arrival.city.title;

    return (
      <Styled.Title font="title.h5">
        {`${cityDep} – ${destionationIntermediates()}${cityArr}`}
      </Styled.Title>
    );
  }

  return (
    <Styled.Header deviceSize={deviceSize}>
      <Styled.HeaderInfo deviceSize={deviceSize}>
        <div>
          <Styled.TitleWrapper deviceSize={deviceSize}>
            <Styled.StatusTagWrapper deviceSize={deviceSize}>
              { renderStatusTag() }
              { renderSurchargeTag() }
            </Styled.StatusTagWrapper>
            { renderTitle() }
          </Styled.TitleWrapper>
          <Styled.TitleMainInfo deviceSize={deviceSize}>
            { renderOrderNumber() }
            { renderOrderDate() }
          </Styled.TitleMainInfo>
        </div>
      </Styled.HeaderInfo>
      <Styled.HeaderPrice deviceSize={deviceSize}>
        <Styled.Price
          dataId={`${dataIdName}.price`}
          amount={currentOrder.payment.price[currentOrder.currency]?.amount || 0}
          currency={currentOrder.currency}
          deviceSize={deviceSize}
          font="title.h4"
        />
        { renderOrderLink() }
      </Styled.HeaderPrice>
    </Styled.Header>
  );

});

export default Header;
