import { useInject } from '@actr/di';
import { AppStore } from '@actr/stores';
import { Text } from '@actr/ui';
import React, { FC, isValidElement, ReactNode } from 'react';

import * as Styled from './styles';

export enum AlertContentJustify {
  START = 'start',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly'
}

export type TitleWithDescription = {
  title: string;
  description: string | never[];
}

export interface OrderAlertContentProps {
  leftSide: ReactNode | TitleWithDescription;
  rightSide: ReactNode | TitleWithDescription;
  justifyContent?: AlertContentJustify;
}

const OrderAlertContent: FC<OrderAlertContentProps> = props => {
  const { leftSide, rightSide, justifyContent = AlertContentJustify.SPACE_BETWEEN } = props;

  const app = useInject(AppStore);
  const { deviceSize } = app;

  function renderSide(element: ReactNode | TitleWithDescription) {
    if (isValidElement(element)) {
      return element;
    }

    if (element && typeof element === 'object' && Object.hasOwn(element, 'title')) {
      return (
        <Styled.AlertContentSlide>
          <Text font="title.h6">
            { (element as TitleWithDescription).title }
          </Text>
          <Text font="paragraph.s">
            { (element as TitleWithDescription).description }
          </Text>
        </Styled.AlertContentSlide>
      );
    }

    return null;
  }

  return (
    <Styled.AlertContent deviceSize={deviceSize} justifyContent={justifyContent}>
      { renderSide(leftSide) }
      { renderSide(rightSide) }
    </Styled.AlertContent>
  );
};

export {
  OrderAlertContent
};
