import { AutotestSupport } from '@actr/ui';
import { INSURANCES } from 'Components/_misc/specs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useOrdersTranslator from '../../../orders/locales';
import { FoldableSection } from '../../_common/foldable-section';
import InsuranceCard from '../../_common/products/insurance-card';
import { AccountOrderTypeProps } from '../../_common/specs';
import * as Styled from './style';

export type AdditionalProductsSectionProps = AccountOrderTypeProps & AutotestSupport;

const InsurancesSection: React.FC<AdditionalProductsSectionProps> = props => {
  const { dataId, order } = props;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${INSURANCES}` : INSURANCES;

  const { insurances } = order.aviaOrder!;

  if (!insurances?.length) {
    return null;
  }

  return (
    <FoldableSection title={t('Страхование')} isInitiallyOpen={true} dataId={dataIdName}>
      <Styled.Products>
        {insurances.map(product => <InsuranceCard product={product} key={product.code} order={order.aviaOrder!} />)}
      </Styled.Products>
    </FoldableSection>
  );
};

export default observer(InsurancesSection);
