import { DeviceSizeProps, styleOnMobile } from '@actr/style';
import { AlertContentJustify } from 'Components/account/main-pages/_common/order-alert-content/index';
import styled from 'styled-components';

export const AlertContentSlide = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const AlertContent = styled
  .div<DeviceSizeProps & { justifyContent: AlertContentJustify} >(({ deviceSize, justifyContent, theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent,
    alignItems: 'flex-start',
    columnGap: theme.padding.x2s,
    rowGap: theme.padding.x2s,
    ...styleOnMobile(deviceSize, {
      flexDirection: 'column',
    })
  }));
