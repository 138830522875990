import { useInject } from '@actr/di';
import { OrdersService, OrdersStore, RealisedPaymentOptions } from '@actr/stores';
import { AutotestSupport, FlexDirection, FlexWrapper, Modal, Text } from '@actr/ui';
import { OrderPaymentCompleteStrategies, Payment, PaymentWaitingManagerRef } from '@actr/widgets';
import { PAYMENT } from 'Components/_misc/specs';
import aviaOrderLog, { AviaOrderLogs } from 'Logs/avia/order';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useInstance } from 'react-ioc';
import aviaPaymentLog, { AviaPaymentLogs } from 'Root/logs/avia/payment';

import useOrdersTranslator from '../../../orders/locales';
import { AccountOrderTypeProps } from '../../_common/specs';

export type PaymentModalProps = AccountOrderTypeProps & AutotestSupport
  & {
    onClose: () => void;
    isOpen: boolean;
  };

const PaymentModal: React.FC<PaymentModalProps> = observer(props => {
  const orders = useInject(OrdersStore);
  const { order, isOpen, onClose, dataId } = props;

  const { t } = useOrdersTranslator();
  const ordersService = useInstance(OrdersService);
  const dataIdName = dataId ? `${dataId}.${PAYMENT}` : PAYMENT;

  const getPaymentInfoAction = orders.getServerAction('getSurchargePaymentInfo');

  const paymentWaitingManagerRef = useRef<PaymentWaitingManagerRef>(null);

  useEffect(() => {
    if (isOpen) {
      aviaOrderLog.send(AviaOrderLogs.SURCHARGES_PAYMENT_MODAL_OPENED, {
        surchargesTypes: order.surcharges.map(surcharge => surcharge.type)
      });
    }
  }, [isOpen]);

  function updateOrder() {
    ordersService.updateV2Order(order);
  }

  function handleClose() {
    onClose();
  }

  async function handlePay() {
    const { currentSurchargePayment } = order;
    if (!currentSurchargePayment.orderInfo) return Promise.reject();

    const currentPaymentOption = currentSurchargePayment.selectedPaymentOption;
    const currentPaymentRequest = currentPaymentOption
        && currentSurchargePayment.getPaymentRequestByPaymentName(currentPaymentOption.name as RealisedPaymentOptions);
    const { bankCard } = currentSurchargePayment;

    const canUseCard = currentPaymentRequest
        && currentPaymentRequest?.canUseCard;

    if (currentPaymentOption
      && (!canUseCard || (canUseCard && bankCard.form.validate()))
      && currentPaymentRequest?.validate()) {

      try {
        await aviaPaymentLog.sendForRequest(
          AviaPaymentLogs.SURCHARGE_PAY,
          () => ordersService
            .pay(
              currentSurchargePayment.selectedPaymentOption?.name as RealisedPaymentOptions,
              {
                await_payment: window.location.href,
                alfa_points: 0,
                payment_info_id: currentSurchargePayment.orderInfo.payment_info_id || undefined,
                billing_number: currentSurchargePayment.orderInfo.billing_number,
                sig: currentSurchargePayment.orderInfo.sig,
              },
              order
            ),
          {
            logDataTry: () => ({
              paymentOption: currentPaymentOption?.name,
              billingNumber: currentSurchargePayment.orderInfo.billing_number,
              sig: currentSurchargePayment.orderInfo.sig
            })
          }
        );

        paymentWaitingManagerRef.current?.startWaiting();

        return {
          isPaymentWaitingStarted: true
        };
      } catch (e: any) { /* empty */ }
    }

    return Promise.reject();
  }

  return (
    <Modal
      open={isOpen}
      Title={(
        <FlexWrapper as="span" direction={FlexDirection.COL}>
          <span>{t('Оплата')}</span>
          <Text font="paragraph.s" extendColor={true}>
            {`${t('Заказ')} ${order.orderInfo.billing_number}`}
          </Text>
        </FlexWrapper>
      )}
      onClose={handleClose}
      isLoading={getPaymentInfoAction.isPending}
      dataId={`${dataIdName}_modal`}>
      {getPaymentInfoAction.isComplete && (
        <Payment
          data-id={dataIdName}
          payment={order.currentSurchargePayment}
          orderPaymentCompleteStrategy={OrderPaymentCompleteStrategies.AVIA_ORDER}
          onOrderStatusChanged={updateOrder}
          paymentWaitingManagerRef={paymentWaitingManagerRef}
          onPaymentComplete={updateOrder}
          onPay={handlePay}
          logId="avia_account_surcharge_payment_form"
        />
      )}
      {
        getPaymentInfoAction.errorInstance?.message
      }
    </Modal>
  );

});

export default PaymentModal;
