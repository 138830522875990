import { styleOnNotLastChild } from '@actr/style';
import { Alert, Button, Text } from '@actr/ui';
import styled from 'styled-components';

export const Notification = styled(Alert)(({ theme }) => ({
  margin: `${theme.margin.m} 0`,
  ...styleOnNotLastChild({
    marginBottom: theme.margin.m
  })
}));

export const AlertText = styled(Text)(() => ({
  display: 'block'
}));

export const RefundButton = styled(Button)(({ theme }) => ({
  marginTop: theme.margin.s
}));
