import { IconChild } from '@actr/icons/child';
import { IconGenderFemale } from '@actr/icons/gender-female';
import { IconGenderMale } from '@actr/icons/gender-male';
import { IconInfant } from '@actr/icons/infant';
import { DeviceSizeProps, styleIf, styleOnMinDesktop, styleOnTablet } from '@actr/style';
import styled from 'styled-components';

interface StatusProps {
  isExchanged: boolean;
  isRefunded: boolean;
}

export const Wrapper = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'flex',
  padding: theme.padding.m,
  ...styleOnTablet(deviceSize, {
    flexDirection: 'column'
  })
}));

export const PassengerIcon = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  ...styleOnMinDesktop(deviceSize, {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '96px',
    flexShrink: 0,
    padding: `${theme.padding.x2s} 0`
  }),
}));

export const InfantIcon = styled(IconInfant)(({ theme }) => ({
  color: theme.colors.graphic.base.normal.default,
  fontSize: theme.iconSize.l,
}));

export const ChildIcon = styled(IconChild)(({ theme }) => ({
  color: theme.colors.graphic.base.normal.default,
  fontSize: theme.iconSize.l,
}));

export const GenderFemaleIcon = styled(IconGenderFemale)(({ theme }) => ({
  color: theme.colors.graphic.base.normal.default,
  fontSize: theme.iconSize.l,
}));

export const GenderMaleIcon = styled(IconGenderMale)(({ theme }) => ({
  color: theme.colors.graphic.base.normal.default,
  fontSize: theme.iconSize.l,
}));

export const TicketNumber = styled.div<DeviceSizeProps & StatusProps>(({ isExchanged, isRefunded, theme }) => ({
  padding: `${theme.margin.x2s} ${theme.margin.s}`,
  border: `1px solid ${theme.colors.border.base.lighter.default}`,
  borderRadius: theme.borderRadius.s,
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  ...styleIf(isRefunded, {
    borderColor: theme.colors.border.error.normal.default
  }),
  ...styleIf(isExchanged, {
    borderColor: theme.colors.border.info.normal.default
  })
}));
