import { AccountOrderModel, OrderModel } from '@actr/stores';
import config from 'Configs';
import { generatePath } from 'react-router';

export default class AccountOrderUtils {

  static makeAviaCheckoutPath(order: OrderModel | AccountOrderModel): string {
    const { billing_number, sig } = order;
    const bookId = `${billing_number}~${sig}`;

    if (config.isForPartnerWL) {
      const params = { 'akf-bookId': bookId };
      return `/?${new URLSearchParams(params)}#avia-checkout`;
    }

    return new URL(generatePath(
      'avia/checkout/:bookId',
      { bookId }
    ), config.lkRedesign.aviakassaURL).pathname;
  }

  static makeRailCheckoutPath(order: OrderModel): string {
    const { billing_number, sig } = order;

    if (config.isForPartnerWL) {
      const params = { 'akf-signature': sig, 'akf-billing_number': billing_number.toString() };
      return `/rail/?${new URLSearchParams(params)}#rail-checkout`;
    }

    return new URL(generatePath(
      'zhd/checkout/:sig/:billingNumber',
      { sig, billingNumber: billing_number }
    ), config.aviakassaURL).pathname;
  }

  static makeHotelCheckoutPath(order: OrderModel): string {
    const { sig, billing_number } = order;
    const { hotel_id, id } = order.tickets[0].hotel || {};
    const bookId = `${billing_number}~${sig}`;

    if (config.isForPartnerWL) {
      const params = { 'akf-hotelId': hotel_id || '', 'akf-roomId': id || '', 'akf-bookId': bookId };
      return `/hotel/?${new URLSearchParams(params)}#hotel-checkout`;
    }

    return new URL(generatePath(
      'hotel/checkout/:hotelId/:roomId/:bookId',
      { hotelId: hotel_id || '', roomId: id || '', bookId }
    ), config.aviakassaURL).pathname;
  }

}
