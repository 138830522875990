import { useInject } from '@actr/di';
import { orderStatuses } from '@actr/specs';
import { AccountAviaAexRefundManager, AppStore, Store } from '@actr/stores';
import AccountAviaPassengersEditingManagerStore from '@actr/stores/src/stores/stores/account/avia/passengers-editing/manager';
import AccountAviaUnsuccessfulTicketingManager from '@actr/stores/src/stores/stores/account/avia/unsuccessful-ticketing';
import { aviaAexRefundLog,
  AviaAexRefundLogs,
  aviaInsuranceRefundLog,
  AviaInsuranceRefundLogs,
  AviaOrderModalOptionsLogs,
  aviaOrderOptionsModalLog,
  aviaOrderUnsuccessfulTicketingLog, AviaOrderUnsuccessfulTicketingLogs } from '@actr/widgets';
import { accountAviaPassengersEditingLog, AccountAviaPassengersEditingLogs } from '@actr/widgets/src/logs/avia/passengers-editing';
import { ACCOUNT_AVIA_ORDER, FOOTER } from 'Components/_misc/specs';
import config from 'Configs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useInstance } from 'react-ioc';
import useOrderExpire from 'Root/hooks/account/orders/use-order-expire';
import ProjectUtils from 'Root/utils/project';

import useOrdersTranslator from '../../../orders/locales';
import CertificatesDownloadButton from '../../_common/certificates-download-button';
import { AccountAviaOrderSectionProps } from '../specs';
import * as Styled from './style';

const FooterSection: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId, order: orderStore } = props;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${FOOTER}` : FOOTER;
  const { accountAviaOrderOptions } = useInstance(Store);
  const app = useInject(AppStore);
  const { deviceSize } = app;
  const refundAexManager = useInject(AccountAviaAexRefundManager);
  const accountAviaPassengersEditingManagerStore = useInject(AccountAviaPassengersEditingManagerStore);
  const accountAviaUnsuccessfulTicketingManager = useInject(AccountAviaUnsuccessfulTicketingManager);

  const order = orderStore.aviaOrder!;

  const { orderIsUpdating, orderCancellation } = useOrderExpire(orderStore);

  const status = order.orderStatus.toLowerCase() as orderStatuses;
  const isBooked = status === orderStatuses.BOOKED;

  // HANDLERS
  function handleOpenOrderOptions() {
    aviaOrderOptionsModalLog.send(AviaOrderModalOptionsLogs.CLICK_OPEN_OPTIONS_MODAL);

    runInAction(() => {
      accountAviaOrderOptions.setOrder(order);
      accountAviaOrderOptions.showOptionsModal = true;
    });
  }

  function handleOpenPassengersEditingModal() {
    accountAviaPassengersEditingLog.send(AccountAviaPassengersEditingLogs.CLICK_OPEN_OPTIONS_MODAL);

    runInAction(() => {
      accountAviaPassengersEditingManagerStore.setOrder(order);
      accountAviaPassengersEditingManagerStore.toggleModal(true);
    });
  }

  function handleOpenAexRefund() {
    aviaAexRefundLog.send(AviaAexRefundLogs.CLICK_OPEN_REFUND_MODAL);

    runInAction(() => {
      accountAviaOrderOptions.setOrder(order);
      refundAexManager.showModal = true;
    });
  }

  function handleOpenInsuranceRefund() {
    aviaInsuranceRefundLog.send(AviaInsuranceRefundLogs.CLICK_OPEN_REFUND_MODAL);

    runInAction(() => {
      accountAviaOrderOptions.setOrder(order);
      accountAviaOrderOptions.refundInsuranceManager.showModal = true;
    });
  }

  function handleOpenUnsuccessfulRefund() {
    aviaOrderUnsuccessfulTicketingLog.send(AviaOrderUnsuccessfulTicketingLogs.OPEN_MODAL, {
      billingNumber: order.billing_number,
    });

    runInAction(() => {
      accountAviaUnsuccessfulTicketingManager.setOrder(order);
      accountAviaUnsuccessfulTicketingManager.toggleIsOpenModal();
    });
  }

  function renderDownloadAllDocs() {
    if (order.documents_download_link && !ProjectUtils.isWebview()) {
      return (
        <Styled.Button
          dataId={`${ACCOUNT_AVIA_ORDER}.download-all`}
          onClick={() => window.open(order.documents_download_link as string, '_blank')}>
          {t('Скачать все архивом')}
        </Styled.Button>
      );
    }

    return null;
  }

  function renderRefundOrExchangeButton() {
    const { allowVoluntaryRefund, allowForcedRefund } = config.avia.orderRefund;
    const { allowForcedExchange, allowVoluntaryExchange } = config.avia.orderExchange;

    const hasNotification = (order.aviaRefundRequest?.hasNewStatus && order.is_avia_refund_available)
      || (order.aviaExchangeRequest?.hasNewStatus && order.is_avia_exchange_available);

    const isDisabled = (!order.aviaRefundRequest && !order.is_avia_refund_available
      && !order.aviaExchangeRequest && !order.is_avia_exchange_available) || orderIsUpdating;

    if ((!allowVoluntaryRefund && !allowForcedRefund && !allowForcedExchange && !allowVoluntaryExchange)
      || ![
        orderStatuses.REFUNDED, orderStatuses.PARTIALLY_REFUNDED, orderStatuses.TICKET, orderStatuses.PARTIALLY_TICKETED
      ].includes(status)) {
      return null;
    }

    return (
      <Styled.Button
        dataId={`${ACCOUNT_AVIA_ORDER}.refund-exchange-button`}
        onClick={handleOpenOrderOptions}
        disabled={isDisabled}
        hasNotification={hasNotification}>
        {t('Обменять или вернуть')}
      </Styled.Button>
    );
  }

  function renderPassengersEditingButton() {
    if (![
      orderStatuses.PARTIALLY_REFUNDED, orderStatuses.TICKET, orderStatuses.PARTIALLY_TICKETED
    ].includes(status) || !config.avia.enablePassengersEditing || !order.is_avia_passenger_edit_available) {
      return null;
    }

    return (
      <Styled.Button
        dataId={`${ACCOUNT_AVIA_ORDER}.passengers-editing-button`}
        onClick={handleOpenPassengersEditingModal}>
        {t('Корректировка данных')}
      </Styled.Button>
    );
  }

  function renderRefundAexButton() {
    const { is_aex_refund_available, aexRefundRequest } = order;
    const { hasNewStatus } = aexRefundRequest || {};

    const isDisabled = (!aexRefundRequest && !is_aex_refund_available) || orderIsUpdating;

    if (isDisabled || !config.avia.enableAexRefund
      || [orderStatuses.BOOKED, orderStatuses.AWAIT_PAYMENT].includes(status)) {
      return null;
    }

    return (
      <Styled.Button
        dataId={`${ACCOUNT_AVIA_ORDER}.aex-refund-button`}
        onClick={handleOpenAexRefund}
        disabled={isDisabled}
        hasNotification={hasNewStatus}>
        {t('Вернуть Аэроэкспресс')}
      </Styled.Button>
    );
  }

  function renderRefundInsuranceButton() {
    const { is_avia_insurance_refund_available, aviaInsuranceRefundRequest } = order;
    const { hasNewStatus } = aviaInsuranceRefundRequest || {};

    const isDisabled = (!aviaInsuranceRefundRequest && !is_avia_insurance_refund_available) || orderIsUpdating;

    if (isDisabled || !config.avia.enableInsuranceRefund
      || [orderStatuses.BOOKED, orderStatuses.AWAIT_PAYMENT].includes(status)) {
      return null;
    }

    return (
      <Styled.Button
        dataId={`${ACCOUNT_AVIA_ORDER}.insurance-refund-button`}
        onClick={handleOpenInsuranceRefund}
        disabled={isDisabled}
        hasNotification={hasNewStatus}>
        {t('Вернуть страховки')}
      </Styled.Button>
    );
  }

  function renderRefundButton() {
    if (!(config.avia.unsuccessfulTicketingRefund.enableRefund && !!order.unsuccessful_ticketing)) {
      return null;
    }

    return (
      <Styled.Button
        dataId={`${ACCOUNT_AVIA_ORDER}.unsuccessful-ticketing-refund-button`}
        loading={orderIsUpdating}
        disabled={orderIsUpdating}
        onClick={handleOpenUnsuccessfulRefund}>
        {t('Управление заказом')}
      </Styled.Button>
    );
  }

  return (
    <Styled.Buttons deviceSize={deviceSize} data-id={dataIdName}>
      {isBooked && !orderStore.isExpired && (
        <Styled.Button
          dataId={`${ACCOUNT_AVIA_ORDER}.cancel-book-button`}
          loading={orderIsUpdating}
          disabled={orderIsUpdating}
          onClick={orderCancellation}>
          {t('Отменить бронь')}
        </Styled.Button>
      )}
      {renderRefundOrExchangeButton()}
      {renderPassengersEditingButton()}
      {renderRefundAexButton()}
      {renderRefundInsuranceButton()}
      {renderRefundButton()}
      {renderDownloadAllDocs()}
      { !!order.certificates.length && (
        <CertificatesDownloadButton order={orderStore} Button={Styled.Button} />
      )}
    </Styled.Buttons>
  );
};

export default observer(FooterSection);
