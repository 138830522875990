import { IconChevronRight } from '@actr/icons/chevron-right';
import { DeviceSizeProps, styleOnMobile, ThemeColorValueTypes } from '@actr/style/src';
import { Tag, Text } from '@actr/ui';
import styled from 'styled-components';

export const LightText = styled(Text)(({ theme }) => ({
  color: theme.colors.text.base.lighter.default
}));

export const RequestsHistoryItemSegment = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const RequestsHistoryItemSegments = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.padding.s,
  rowGap: theme.padding.x3s
}));

export const RequestsHistoryItemStatusTag = styled(Tag)<{themeColor: ThemeColorValueTypes}>(({ theme, themeColor }) => ({
  textTransform: 'uppercase',
  height: theme.fontLineHeight.s,
  backgroundColor: theme.colors.surface[themeColor].light.default,
  borderColor: theme.colors.border[themeColor].normal.default,
  color: theme.colors.text[themeColor].normal.default,
  '&, &:focus, &:hover, &:active': {
    backgroundColor: theme.colors.surface[themeColor].light.default,
    borderColor: theme.colors.border[themeColor].normal.default,
    color: theme.colors.text[themeColor].normal.hover,
  },
}));

export const RequestsHistoryItemFakeButtonIcon = styled(IconChevronRight)(({ theme }) => ({
  width: theme.iconSize.xs,
  height: theme.iconSize.xs,
  maxWidth: theme.iconSize.xs,
  maxHeight: theme.iconSize.xs
}));

export const RequestsHistoryItemFakeButton = styled.div(({ theme }) => ({
  borderRadius: theme.borderRadius.s,
  backgroundColor: theme.colors.surface.mute.darker.default,
  color: theme.colors.graphic.contrast.darker.default,
  padding: theme.padding.x3s,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const RequestsHistoryItemStatusInfo = styled.div(({ theme }) => ({
  display: 'flex',
  columnGap: theme.padding.s,
  marginLeft: 'auto',
  alignItems: 'center',
  [LightText]: {
    whiteSpace: 'nowrap'
  }
}));

export const RequestsHistoryItemTitle = styled(Text)<DeviceSizeProps>(({ deviceSize }) => ({
  minWidth: '202px',
  ...styleOnMobile(deviceSize, {
    minWidth: 'auto'
  })
}));

export const RequestsHistoryItem = styled.div<DeviceSizeProps>(({ theme, deviceSize }) => ({
  display: 'flex',
  columnGap: theme.padding.m,
  alignItems: 'flex-start',
  width: '100%',
  ...styleOnMobile(deviceSize, {
    flexDirection: 'column',
    rowGap: theme.padding.x2s,
    [RequestsHistoryItemStatusInfo]: {
      marginLeft: 0
    }
  })
}));

export const RequestsHistoryItemWrapper = styled.div({
  width: '100%',
});

export const RequestsHistory = styled.div(({ theme }) => ({
  padding: theme.padding.m,
  border: `1px solid ${theme.colors.border.base.light.default}`,
  borderRadius: theme.borderRadius.m
}));

export const Delimiter = styled.div(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.colors.border.base.light.default,
  margin: `${theme.margin.xs} 0`
}));
