import { useInject } from '@actr/di';
import {
  AdditionalProductsStatuses,
  AviaOrderAdditionalServicesBaggageStatus,
  AviaOrderAdditionalServicesPlacePickStatus,
  AviaPostBookingProductsTypes
} from '@actr/specs';
import {
  AccountAviaOrderAdditionalServicesBaggage,
  AccountAviaOrderAdditionalServicesPlaceChoice,
  AppStore,
  AviaOrderInsurancePassenger
} from '@actr/stores';
import { FontWeights, ThemeColorValueImportance } from '@actr/style';
import { TagSizes, TagTypes } from '@actr/ui';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { AccountAviaOrderModelProps } from 'Components/account/main-pages/v2orders/_common/specs';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react-lite';
import React from 'react';

import * as Styled from './styles';

export type ProductCardTicketInfoStatus = AdditionalProductsStatuses
  | AviaOrderAdditionalServicesPlacePickStatus | AviaOrderAdditionalServicesBaggageStatus;

interface ProductCardPartWithPassengersProps {
  comment?: string;
  status?: ProductCardTicketInfoStatus;
  price: number;
  insurancePassengers?: AviaOrderInsurancePassenger[];
  aexTickets?: {
    document_url: string;
    status: AdditionalProductsStatuses;
  }[];
  baggageItems?: AccountAviaOrderAdditionalServicesBaggage[];
  passengerPlaceItems?: AccountAviaOrderAdditionalServicesPlaceChoice[];
  productType: AviaPostBookingProductsTypes;
}

const ProductCardTicketInfo:React.FC<ProductCardPartWithPassengersProps & AccountAviaOrderModelProps> = observer(props => {
  const { aexTickets, baggageItems, status: overallStatus, price, comment,
    passengerPlaceItems, productType, insurancePassengers, order } = props;
  const app = useInject(AppStore);
  const { deviceSize } = app;

  const { t } = useOrdersTranslator();

  const isBooked = [AdditionalProductsStatuses.BOOKED,
    AviaOrderAdditionalServicesPlacePickStatus.BOOKED,
    AviaOrderAdditionalServicesBaggageStatus.Booked].includes(overallStatus);
  const isRefunded = [AdditionalProductsStatuses.REFUNDED,
    AdditionalProductsStatuses.REFUND_AUTHORIZED,
    AviaOrderAdditionalServicesPlacePickStatus.REFUNDED,
    AviaOrderAdditionalServicesBaggageStatus.Refunded].includes(overallStatus);
  const isTicketed = [AdditionalProductsStatuses.TICKETED,
    AviaOrderAdditionalServicesPlacePickStatus.TICKETED,
    AviaOrderAdditionalServicesBaggageStatus.Ticketed].includes(overallStatus);
  const isPaid = [AdditionalProductsStatuses.PAID,
    AviaOrderAdditionalServicesPlacePickStatus.PAID,
    AviaOrderAdditionalServicesBaggageStatus.Paid]
    .includes(overallStatus) || isTicketed;

  function renderStatusTag(title: string) {
    if (!title) return null;
    return (
      <Styled.SegmentStatusTag size={TagSizes.XS} tagType={TagTypes.TERTIARY}>
        {title}
      </Styled.SegmentStatusTag>
    );
  }

  function getStatusText(): string {
    if (isBooked) {
      return t('Не оплачено').toUpperCase();
    }
    if (isRefunded) {
      return t('Возвращено').toUpperCase();
    }
    return '';
  }

  function renderSegmentStatusTag() {
    const title = getStatusText();
    return renderStatusTag(title);
  }

  function renderBaggageItems() {
    if (!baggageItems?.length) return null;
    const itemGroups = groupBy(baggageItems, item => `${item.name?.last} ${item.name?.first} ${item.name?.middle}`);

    return (
      <>
        {Object.values(itemGroups).map(group => (
          <Styled.DetailsInfoCell
            titleWeight={isPaid ? FontWeights.SEMI : undefined}
            title={`${group[0].name?.first} ${group[0].name?.last}`}>
            {isPaid && (
              <Styled.PassengerServiceInfoText>
                {group.map((item, idx) => (
                  <Styled.PassengerServiceInfoTextItem>
                    {`${item.city_dep} – ${item.city_arr} — ${item.count}x${item.weight}${t('кг')}${idx !== group.length - 1 ? ', ' : ''}`}
                  </Styled.PassengerServiceInfoTextItem>
                ))}
              </Styled.PassengerServiceInfoText>
            )}
          </Styled.DetailsInfoCell>
        ))}
      </>
    );
  }

  function renderPassengersPlaces() {
    if (!passengerPlaceItems?.length) return null;
    const itemGroups = groupBy(passengerPlaceItems, item => `${item.name?.last} ${item.name?.first} ${item.name?.middle}`);

    return (
      <>
        {Object.values(itemGroups).map(group => (
          <Styled.DetailsInfoCell
            titleWeight={isPaid ? FontWeights.SEMI : undefined}
            title={`${group[0].name?.first} ${group[0].name?.last}`}>
            {isPaid && (
              <Styled.PassengerServiceInfoText>
                {group.map((item, idx) => (
                  <Styled.PassengerServiceInfoTextItem>
                    {`${item.city_dep} – ${item.city_arr} — ${item.row}${item.chair}${idx !== group.length - 1 ? ', ' : ''}`}
                  </Styled.PassengerServiceInfoTextItem>
                ))}
              </Styled.PassengerServiceInfoText>
            )}
          </Styled.DetailsInfoCell>
        ))}
      </>
    );
  }

  function renderAexTickets() {
    if (!aexTickets?.length) return null;

    return aexTickets.map(ticket => renderDownloadItem({
      text: t('Билет на Аэроэкспресс'),
      url: ticket.document_url,
      status: ticket.status,
    }));
  }

  function handleDownload(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  function renderInsurancePassengers() {
    if (!insurancePassengers?.length) return null;

    return insurancePassengers.map(passenger => (
      renderDownloadItem({
        text: `${passenger.name?.first} ${passenger.name?.last}`,
        url: passenger.document_url,
        status: passenger.status,
      })
    ));
  }

  function renderComment() {
    if (!comment) return null;

    return (
      <Styled.Comment color={ThemeColorValueImportance.LIGHTER}>
        {comment}
      </Styled.Comment>
    );
  }

  function renderDownloadItem(params: {text: string; url?: string; status: AdditionalProductsStatuses}) {
    if (isPaid && params.url) {
      const isInsurance = productType === AviaPostBookingProductsTypes.INSURANCE;

      return (
        <Styled.DetailsInfoCell
          title={params.text}
          onClick={() => handleDownload(params.url)}
          titleWeight={FontWeights.SEMI}>
          <Styled.DownloadLink underlined>
            {isInsurance ? t('Скачать полис') : t('Скачать билет')}
          </Styled.DownloadLink>
        </Styled.DetailsInfoCell>
      );
    }

    return (
      <Styled.DetailsInfoCell title={params.text} />
    );
  }

  return (
    <Styled.ActivePart isRefunded={isRefunded}>
      <Styled.ActivePartInfo>
        <Styled.ActivePartPrice
          font="title.h6"
          deviceSize={deviceSize}
          amount={price}
          currency={order.currency}
          isBooked={isBooked}
          isRefunded={isRefunded}
          extendColor={isBooked}
        />
        { renderSegmentStatusTag() }
      </Styled.ActivePartInfo>
      { renderComment() }
      { renderAexTickets() }
      { renderInsurancePassengers() }
      { renderBaggageItems() }
      { renderPassengersPlaces() }
    </Styled.ActivePart>
  );
});

export default ProductCardTicketInfo;
