import config from '@actr/config';
import { orderStatuses } from '@actr/specs';
import { AlertTypes } from '@actr/ui';
import { ACCOUNT } from 'Components/_misc/specs';
import useOrdersTranslator from 'Components/account/main-pages/v2orders/locales';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';

import { AccountOrderCardProps } from '../specs';
import * as Styled from './style';

export const Alerts: React.FC<AccountOrderCardProps> = observer(props => {
  const { order, isAviaCard, isHotelCard, isRailCard } = props;
  const { t } = useOrdersTranslator();

  const expireCountdown = (sec: number) => {
    const min = Math.floor(sec / 60);

    const seconds = Math.floor(sec % 60);
    const hours = Math.floor(min / 60);
    const minutes = min % 60;

    const readingFormat = (n: number, unit: string, hideRule?: () => boolean) => {
      if (hideRule?.()) {
        return '';
      }

      if (n > 0) {
        if (n.toString().length < 2) {
          return `0${n} ${t(unit)}`;
        }

        return `${n} ${t(unit)}`;
      }

      return `00 ${t(unit)}`;
    };

    return {
      hours: readingFormat(hours, 'ч', () => hours <= 0),
      minutes: readingFormat(minutes, 'мин', () => hours <= 0 && minutes <= 0),
      seconds: readingFormat(seconds, 'сек')
    };
  };

  const expireDate = useMemo(() => {
    let date;

    if (isAviaCard) {
      date = order.aviaOrder!.expire;
    }
    if (isHotelCard) {
      date = order.regularOrder!.tickets[0].hotel?.expired;
    } else if (isRailCard) {
      date = order.regularOrder!.tickets[0].train?.expiredMoment;
    }
    return moment(date, config.dateTime.serverDateTimeFormat);
  }, []);

  let content = null;
  const countDown = expireCountdown(order.expireTimer.timeRemaining);

  if (order.orderStatus.toLowerCase() === orderStatuses.BOOKED) {
    content = (
      <Styled.Alert
        dataId={`${ACCOUNT}-${order.orderType}-order.expire-date-alert`}
        type={order.isExpired ? AlertTypes.DANGER : AlertTypes.WARNING}
        title={(
          <Styled.AlertText font="title.h7">
            { order.isExpired ? t('Бронь истекла') : t('Бронь истекает') }
            {' '}
            {expireDate?.format('DD.MM.YYYY')}
            {' '}
            {t('в')}
            {' '}
            {expireDate?.format('HH:mm')}
            {' '}
            {t('по МСК')}
            {' '}
            (UTC +3)
            {' '}
            { !order.isExpired
            && `${t('Осталось')} 
            
            ${countDown.hours}
            ${countDown.minutes}
            ${countDown.seconds}
            `}
          </Styled.AlertText>
      )}
      />
    );
  }

  if (order.orderStatus.toLowerCase() === orderStatuses.PAID) {
    content = (
      <Styled.Alert
        type={AlertTypes.INFO}
        title={(
          <Styled.AlertText font="title.h7">
            {t('Ваша бронь успешно оплачена! Пожалуйста, подождите...')}
          </Styled.AlertText>
        )}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    content
  );
});
