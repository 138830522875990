import { AlertTypes } from "@actr/ui";

export enum AlertSegmentChangeTypes {
  EXCHANGED = 'EXCHANGED',
  REFUNDED = 'REFUNDED',
}

export type AlertNotification = {
  title: string;
  type?: AlertTypes;
  segmentChangeType: AlertSegmentChangeTypes;
}
