import RequestsHistorySection from 'Components/account/main-pages/v2orders/avia/requests-history';
import styled from 'styled-components';

import AdditionalProductsSection from './additional-products/index';
import DocumentsSection from './documents/index';
import FooterSection from './footer';
import InsurancesSection from './insurances';
import PassengersSection from './passengers/index';
import PaymentHistorySection from './payment_history/index';

export const Passengers = styled(PassengersSection)(() => ({
}));

export const AdditionalProducts = styled(AdditionalProductsSection)(() => ({
}));

export const Insurances = styled(InsurancesSection)(() => ({
}));

export const Documents = styled(DocumentsSection)(() => ({
}));

export const Footer = styled(FooterSection)(() => ({
}));

export const PaymentHistory = styled(PaymentHistorySection)(() => ({
}));

export const RequestsHistory = styled(RequestsHistorySection)(() => ({
}));
