import { useInject } from '@actr/di';
import { useNavigation } from '@actr/hooks';
import { OrderStatusGroup, orderTypes, serverActionStatuses } from '@actr/specs';
import {
  AccountAviaAexRefundManager, AccountAviaOrderModel,
  AccountAviaRefundManager,
  HotelRefundStore,
  OrdersService,
  OrdersStore,
  RailRefundStore,
  Store
} from '@actr/stores';
import AccountAviaPassengersEditingManagerStore from '@actr/stores/src/stores/stores/account/avia/passengers-editing/manager';
import AccountAviaUnsuccessfulTicketingManager
  from '@actr/stores/src/stores/stores/account/avia/unsuccessful-ticketing';
import { AccountRoutes,
  aviaAexRefundLog,
  aviaExchangeLog,
  aviaInsuranceRefundLog,
  AviaOrderExchangeLogs,
  AviaOrderRefundLogs,
  aviaOrderUnsuccessfulTicketingLog,
  AviaOrderUnsuccessfulTicketingLogs, aviaRefundLog } from '@actr/widgets';
import { accountAviaPassengersEditingLog, AccountAviaPassengersEditingLogs } from '@actr/widgets/src/logs/avia/passengers-editing';
import { reaction, runInAction, transaction } from 'mobx';
import { useEffect, useRef, useState } from 'react';
import { useInstance } from 'react-ioc';
import ProjectUtils from 'Root/utils/project';

const BILLING_NUMBER_URL_PARAMETER = 'billingNumber';
const AVIA_REFUND_BILLING_NUMBER_URL_PARAMETER = 'aviaRefundOrderBookingNumber';
const AVIA_EXCHANGE_BILLING_NUMBER_URL_PARAMETER = 'aviaExchangeOrderBookingNumber';
const AVIA_AEX_BILLING_NUMBER_URL_PARAMETER = 'aviaRefundAexOrderBookingNumber';
const AVIA_INSURANCE_BILLING_NUMBER_URL_PARAMETER = 'aviaRefundInsuranceOrderBookingNumber';
const UNSUCCESSFUL_TICKETING_REFUND_BILLING_NUMBER_URL_PARAMETER = 'unsuccessfulTicketingRefund';
const AVIA_PASSENGERS_EDITING_BILLING_NUMBER_URL_PARAMETER = 'aviaPassengersEditingBillingNumber';

function useUpdateStatusRefund({
  initialLoadOrders
}: {initialLoadOrders: boolean} = { initialLoadOrders: !ProjectUtils.isSber() && !ProjectUtils.isRshb() }) {
  const {
    accountAviaOrderOptions,
    railErChange,
    payment
  } = useInstance(Store);
  const hotelRefundStore = useInject(HotelRefundStore);
  const railRefund = useInject(RailRefundStore);
  const ordersService = useInstance(OrdersService);
  const refundManager = useInject(AccountAviaRefundManager);
  const accountAviaUnsuccessfulTicketingManager = useInject(AccountAviaUnsuccessfulTicketingManager);
  const accountAviaPassengersEditingManagerStore = useInject(AccountAviaPassengersEditingManagerStore);
  const ordersStore = useInject(OrdersStore);
  const { V2orders: orders } = ordersStore;
  const {
    exchangeManager,
    refundInsuranceManager,
  } = accountAviaOrderOptions;
  const refundAexManager = useInject(AccountAviaAexRefundManager);
  const { confirmationStore, creationStore } = refundManager;
  const allowUpdateOrders = useRef(false);
  const { searchParams, updateRoute } = useNavigation(AccountRoutes.ORDERS);
  const [orderModalWasOpening, setOrderModalWasOpening] = useState(false);
  const [showBookCancelError, setShowBookCancelError] = useState(false);

  const cancelOrderAction = ordersStore.getServerAction('cancelOrder');
  const cancelAviaOrderAction = ordersStore.getServerAction('cancelAviaBooking');
  const cancelRailOrderAction = ordersStore.getServerAction('cancelRailwayBooking');
  const cancelHotelOrderAction = ordersStore.getServerAction('cancelHotelBooking');
  const getOrdersAction = ordersStore.getServerAction('getOrders');

  const aviaRefundOrderBookingNumber = searchParams[AVIA_REFUND_BILLING_NUMBER_URL_PARAMETER];
  const aviaExchangeOrderBookingNumber = searchParams[AVIA_EXCHANGE_BILLING_NUMBER_URL_PARAMETER];
  const aviaRefundAexOrderBookingNumber = searchParams[AVIA_AEX_BILLING_NUMBER_URL_PARAMETER];
  const aviaRefundInsuranceOrderBookingNumber = searchParams[AVIA_INSURANCE_BILLING_NUMBER_URL_PARAMETER];
  const billingNumber = searchParams[BILLING_NUMBER_URL_PARAMETER];
  const aviaUnsuccessfulTicketingRefundBookingNumber = searchParams[UNSUCCESSFUL_TICKETING_REFUND_BILLING_NUMBER_URL_PARAMETER];
  const aviaPassengersEditingBookingNumber = searchParams[AVIA_PASSENGERS_EDITING_BILLING_NUMBER_URL_PARAMETER];

  const billingNumberFromUrl = (
    aviaRefundOrderBookingNumber
    || aviaExchangeOrderBookingNumber
    || aviaRefundInsuranceOrderBookingNumber
    || aviaRefundAexOrderBookingNumber
    || billingNumber
    || aviaUnsuccessfulTicketingRefundBookingNumber
    || aviaPassengersEditingBookingNumber
  ) as string;

  useEffect(() => {
    if (ProjectUtils.isWOWTickets()) {
      ordersStore.ordersFilter.order_type = orderTypes.AVIA;
    }
  }, []);

  function updateOrders(
    filters?: Parameters<OrdersService['getOrders']>[0],
    paginationInfo?: Parameters<OrdersService['getOrders']>[1]
  ) {
    if (ordersStore.ordersFilter.billing_number && billingNumberFromUrl) {
      updateRoute({
        searchParams: {
          [BILLING_NUMBER_URL_PARAMETER]: undefined,
          [AVIA_REFUND_BILLING_NUMBER_URL_PARAMETER]: undefined,
          [AVIA_EXCHANGE_BILLING_NUMBER_URL_PARAMETER]: undefined,
          [AVIA_INSURANCE_BILLING_NUMBER_URL_PARAMETER]: undefined,
          [AVIA_AEX_BILLING_NUMBER_URL_PARAMETER]: undefined,
          [UNSUCCESSFUL_TICKETING_REFUND_BILLING_NUMBER_URL_PARAMETER]: undefined,
          [AVIA_PASSENGERS_EDITING_BILLING_NUMBER_URL_PARAMETER]: undefined,
        }
      });
    }

    if (ordersStore.ordersFilter?.statuses_group && filters?.billing_number) {
      return ordersService.getV2Orders({
        ...filters,
        statuses_group: undefined
      }, paginationInfo);
    }
    if (!ordersStore.ordersFilter?.statuses_group && filters?.statuses_group && ordersStore.ordersFilter?.billing_number) {
      return ordersService.getV2Orders({
        ...filters,
        billing_number: undefined
      }, paginationInfo);
    }
    return ordersService.getV2Orders(filters, paginationInfo);
  }

  useEffect(() => {
    const refundOrderReactionDisposer = reaction(
      () => [
        hotelRefundStore.getServerAction('refund').status,
        railRefund.getServerAction('refund').status,
        railErChange.getServerAction('erChange').status,

        confirmationStore.getServerAction('confirm').status,
        creationStore.getServerAction('createRequest').status,
        creationStore.getServerAction('void').status,
        refundManager.currentRequest?.getServerAction('waiverRequest').status,
        refundManager.currentRequest?.getServerAction('actualizeRequest')?.status,

        refundAexManager.creationStore.getServerAction('createRequest').status,
        refundAexManager.currentRequest?.getServerAction('actualizeRequest')?.status,
        refundAexManager.currentRequest?.getServerAction('confirmRequest')?.status,
        refundAexManager.currentRequest?.getServerAction('cancelRequest')?.status,

        refundInsuranceManager.creationStore.getServerAction('createRequest').status,
        refundInsuranceManager.currentRequest?.getServerAction('actualizeRequest')?.status,
        refundInsuranceManager.currentRequest?.getServerAction('confirmRequest')?.status,
        refundInsuranceManager.currentRequest?.getServerAction('cancelRequest')?.status,

        accountAviaUnsuccessfulTicketingManager?.getServerAction('cancelRequest')?.status,
        accountAviaUnsuccessfulTicketingManager?.getServerAction('actualizeRequest')?.status,
        accountAviaPassengersEditingManagerStore?.getServerAction('createRequest')?.status,

        accountAviaUnsuccessfulTicketingManager?.getServerAction('createRequestRefund')?.status,

        exchangeManager.creationStore.getServerAction('createRequest').status,
        exchangeManager.recommendationSelectionStore.getServerAction('bookRecommendation').status,
        exchangeManager.currentRequest?.getServerAction('actualizeRequest')?.status,
        exchangeManager.currentRequest?.getServerAction('cancelRequest')?.status,
        exchangeManager.currentRequest?.getServerAction('recreateSurcharge')?.status,
        payment.activePaymentRequest?.payAction.status,
      ],
      statuses => {
        if (statuses.includes(serverActionStatuses.COMPLETE)) {
          allowUpdateOrders.current = true;
        }
      }
    );
    const modalsClosingReactionDisposer = reaction(
      () => [
        refundManager.showModal,
        exchangeManager.showModal,
        hotelRefundStore.openModal,
        railRefund.showModal,
        railErChange.showModal,
        refundAexManager.showModal,
        refundInsuranceManager.showModal,
        accountAviaUnsuccessfulTicketingManager.isOpenModal,
        accountAviaPassengersEditingManagerStore.isModalOpen,
      ],
      openingStates => {
        if (openingStates.every(state => !state) && allowUpdateOrders.current) {
          updateOrders();
          allowUpdateOrders.current = false;
        }
      }
    );

    return () => {
      refundOrderReactionDisposer();
      modalsClosingReactionDisposer();
    };
  }, []);

  useEffect(() => {
    if (getOrdersAction.isComplete && orders[0]) {
      const currentOrder = orders[0].aviaOrder || orders[0].regularOrder;

      if (currentOrder && !orderModalWasOpening) {
        if (aviaRefundOrderBookingNumber) {
          aviaRefundLog.send(AviaOrderRefundLogs.OPEN_MODAL_FROM_URL);

          transaction(() => {
            refundManager.reset();
            accountAviaOrderOptions.setOrder(currentOrder);
            refundManager.showModal = true;
            setOrderModalWasOpening(true);
          });
        }

        if (aviaRefundAexOrderBookingNumber) {
          aviaAexRefundLog.send(AviaOrderRefundLogs.OPEN_MODAL_FROM_URL);
          transaction(() => {
            refundAexManager.reset();
            accountAviaOrderOptions.setOrder(currentOrder);
            refundAexManager.showModal = true;
            setOrderModalWasOpening(true);
          });
        }

        if (aviaRefundInsuranceOrderBookingNumber) {
          aviaInsuranceRefundLog.send(AviaOrderRefundLogs.OPEN_MODAL_FROM_URL);
          transaction(() => {
            refundInsuranceManager.reset();
            accountAviaOrderOptions.setOrder(currentOrder);
            refundInsuranceManager.showModal = true;
            setOrderModalWasOpening(true);
          });
        }

        if (aviaExchangeOrderBookingNumber) {
          aviaExchangeLog.send(AviaOrderExchangeLogs.OPEN_MODAL_FROM_URL);

          transaction(() => {
            exchangeManager.reset();
            accountAviaOrderOptions.setOrder(currentOrder);
            exchangeManager.showModal = true;
            setOrderModalWasOpening(true);
          });
        }

        if (aviaUnsuccessfulTicketingRefundBookingNumber) {
          aviaOrderUnsuccessfulTicketingLog.send(AviaOrderUnsuccessfulTicketingLogs.OPEN_MODAL_FROM_URL, {
            billingNumber: currentOrder.billing_number,
          });

          runInAction(() => {
            accountAviaUnsuccessfulTicketingManager.setOrder(currentOrder as AccountAviaOrderModel);

            accountAviaUnsuccessfulTicketingManager.toggleIsOpenModal();

            setOrderModalWasOpening(true);
          });
        }
        if (aviaPassengersEditingBookingNumber) {
          accountAviaPassengersEditingLog.send(AccountAviaPassengersEditingLogs.OPEN_MODAL_FROM_URL, {
            billingNumber: currentOrder.billing_number,
          });

          runInAction(() => {
            accountAviaPassengersEditingManagerStore.setOrder(currentOrder as AccountAviaOrderModel);

            accountAviaPassengersEditingManagerStore.toggleModal();

            setOrderModalWasOpening(true);
          });
        }
      }
    }
  }, [getOrdersAction.status]);

  useEffect(() => {
    if (cancelOrderAction.isError) {
      setShowBookCancelError(true);
    }
  }, [cancelOrderAction.status]);

  useEffect(() => {
    if (getOrdersAction.isEmpty && !billingNumberFromUrl && initialLoadOrders) {
      updateOrders({
        statuses_group: OrderStatusGroup.BOOKED
      }).then(res => {
        if (!res.length) {
          updateOrders({
            statuses_group: OrderStatusGroup.TICKETED
          });
        }
      });
    } else if (billingNumberFromUrl && initialLoadOrders) {
      updateOrders({ billing_number: parseInt(billingNumberFromUrl, 10) });
    }

    return () => {
      transaction(() => {
        getOrdersAction.cancel();
        cancelAviaOrderAction.cancel();
        cancelRailOrderAction.cancel();
        cancelHotelOrderAction.cancel();
        ordersStore.reset();
        railRefund.reset();
        hotelRefundStore.reset();
        refundManager.reset();
        accountAviaOrderOptions.refundAexManager.reset();
        accountAviaOrderOptions.refundInsuranceManager.reset();
        accountAviaOrderOptions.exchangeManager.reset();
        payment.reset();
      });
    };
  }, []);

  return {
    updateOrders,
    showBookCancelError,
    setShowBookCancelError,
  };
}

export default useUpdateStatusRefund;
