import { DeviceSizeProps, styleOnLastChild, styleOnMobile } from '@actr/style/src';
import { Skeleton } from '@actr/ui/src';
import styled from 'styled-components';

export const Card = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  background: theme.colors.background.base.light.default,
  borderRadius: theme.borderRadius.l,
  border: `1px solid ${theme.colors.border.base.light.default}`,
  padding: theme.padding.m,
  ...styleOnMobile(deviceSize, {
    [FullSizeWrapper]: {
      flexDirection: 'column'
    }
  })
}));

export const FullSizeWrapper = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginBottom: theme.margin.m,
  ...styleOnLastChild({
    marginBottom: 0
  })
}));

export const FullSizeWrapperSmallMargin = styled(FullSizeWrapper)(({ theme }) => ({
  marginBottom: theme.margin.xs,
}));

export const SmallTextSkeleton = styled(Skeleton)(({ theme }) => ({
  padding: 0,
  borderRadius: theme.borderRadius.m
}));

export const SmallTextSkeletonStaticMargin
  = styled(SmallTextSkeleton)<DeviceSizeProps>(({ deviceSize, theme }) => ({
    marginRight: theme.margin.m,
    ...styleOnMobile(deviceSize, {
      marginRight: 0,
      marginBottom: theme.margin.x2s
    })
  }));

export const SmallTextSkeletonAutoMargin
  = styled(SmallTextSkeleton)<DeviceSizeProps>(({ deviceSize }) => ({
    marginLeft: 'auto',
    ...styleOnMobile(deviceSize, {
      marginLeft: 0
    })
  }));

export const SmallTextSkeletonColumn = styled(SmallTextSkeleton)<DeviceSizeProps>(({ deviceSize, theme }) => ({
  marginRight: theme.margin.xs,
  ...styleOnLastChild({
    marginRight: 0,
  }),
  ...styleOnMobile(deviceSize, {
    marginRight: 0,
    marginBottom: theme.margin.xs,
    ...styleOnLastChild({
      marginBottom: 0,
    }),
  })
}));
