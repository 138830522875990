import { ADDITIONAL_PRODUCTS } from 'Components/_misc/specs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useOrdersTranslator from '../../../orders/locales';
import { FoldableSection } from '../../_common/foldable-section';
import AdditionalServiceCards from '../../_common/products/additional-service-card';
import AeroexpressCard from '../../_common/products/aeroexpress-card';
import AviakassaProductCard from '../../_common/products/aviakassa-product-card';
import { AccountAviaOrderSectionProps } from '../specs';
import * as Styled from './style';

const AdditionalProductsSection: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId, order } = props;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${ADDITIONAL_PRODUCTS}` : ADDITIONAL_PRODUCTS;

  const aviaOrder = order.aviaOrder!;
  const { additional_products, additionalServices, aeroexpress } = aviaOrder;
  const { baggage, place_choice } = additionalServices;

  if (!additional_products?.length && !aeroexpress?.length && !baggage && !place_choice) {
    return null;
  }

  return (
    <FoldableSection title={t('Дополнительные услуги')} isInitiallyOpen={true} dataId={dataIdName}>
      <Styled.Products>
        <AdditionalServiceCards products={additionalServices} order={aviaOrder} />
        {additional_products.map(product => <AviakassaProductCard product={product} key={product.id} order={aviaOrder} />)}
        {aeroexpress.map(product => <AeroexpressCard product={product} key={product.id} order={aviaOrder} />)}
      </Styled.Products>
    </FoldableSection>
  );
};

export default observer(AdditionalProductsSection);
