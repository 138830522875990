import { DeviceSizeProps } from '@actr/style';
import * as UI from '@actr/ui';
import styled from 'styled-components';

export const Modal = styled(UI.Modal)(() => ({
  maxWidth: 800
}));

export const FlightInfoGridCol = styled(UI.GridCol)<DeviceSizeProps>(() => ({
}));

export const WraperGrid = styled(UI.GridRow)(() => ({
}));

export const Accordion = styled(UI.Accordion)(() => ({
}));
