import { AviaOrderLocatorInfoSegments } from '@actr/stores';
import { Sizes } from '@actr/style';
import { GridCol, GridRow, Space } from '@actr/ui';
import { getFlightRelatedInfo } from '@actr/widgets';
import { FlightRelatedInfoProps, TagGroups, useAviaFlightSegmentsProcessing } from '@actr/widgets/src/components/avia/flight';
import CarrierComment from '@actr/widgets/src/components/avia/flight/info-modal/comments';
import TransferInfo from '@actr/widgets/src/components/avia/flight/info-modal/transfer';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import FlightSegmentsInfo from '../segment-info';

export type RouteInfoProps = {
  segments: AviaOrderLocatorInfoSegments;
} & FlightRelatedInfoProps;

function getTransferData(segments: AviaOrderLocatorInfoSegments, currentSegmentIndex: number) {
  const { segmentsNewFormat, segmentsOldFormat } = segments;

  if (currentSegmentIndex === segmentsNewFormat.length - 1) return null;

  const nextSegmentIndex = segmentsNewFormat.findIndex((segment, index) => index > currentSegmentIndex && !segment.isRefunded);
  if (nextSegmentIndex > 0) {
    const currentSegments = [segmentsOldFormat[currentSegmentIndex], segmentsOldFormat[nextSegmentIndex]];
    const { transfers } = useAviaFlightSegmentsProcessing({
      segments: currentSegments
    }, getFlightRelatedInfo(null, [segmentsNewFormat[currentSegmentIndex], segmentsNewFormat[nextSegmentIndex]]));

    return {
      segments: currentSegments,
      transferInfo: transfers[0]
    };
  }
  return null;
}

const RouteInfo: FC<RouteInfoProps> = props => {
  const { segments: flightSegments, flightInfo } = props;

  return (
    <div>
      {flightSegments.segmentsNewFormat.map((_segment, segmentIndex) => {
        const transfer = getTransferData(flightSegments, segmentIndex);

        return (
          <>
            <GridRow>
              <GridCol span={12}>
                <FlightSegmentsInfo
                  segments={flightSegments}
                  flightInfo={flightInfo}
                  isInInfoModal={true}
                  isRoute={false}
                  disableInfoOpening={true}
                  omitTagGroups={[TagGroups.TRANSFER]}
                  segmentIndexes={[segmentIndex]}
                />
              </GridCol>
              {flightSegments.segmentsOldFormat[segmentIndex].comment && !flightSegments.segmentsNewFormat[segmentIndex].isRefunded
                && (
                <GridCol span={12}>
                  <CarrierComment comment={flightSegments.segmentsOldFormat[segmentIndex].comment} />
                </GridCol>
                )}
              {transfer && (
              <GridCol span={12}>
                <TransferInfo
                  transferInfo={transfer.transferInfo}
                  flightInfo={flightInfo}
                  segments={transfer.segments}
                  segmentIndexes={[0, 1]}
                />
              </GridCol>
              )}
            </GridRow>
            <Space size={Sizes.M} />
          </>
        );
      })}
    </div>
  );
};

export default observer(RouteInfo);
