import { useInject } from '@actr/di';
import { AppStore } from '@actr/stores';
import { AutotestSupport } from '@actr/ui';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import * as Styled from './style';

export interface FoldableSectionProps extends AutotestSupport {
  title: string;
  children: React.ReactNode;
  isInitiallyOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const FoldableSection: React.FC<FoldableSectionProps> = observer(({
  title,
  children,
  dataId,
  isInitiallyOpen = true,
  onToggle
}) => {
  const [open, setIsOpen] = useState(isInitiallyOpen);

  const app = useInject(AppStore);
  const { deviceSize } = app;

  function toggleOpen() {
    setIsOpen(prev => !prev);
    onToggle?.(!open);
  }

  return (
    <Styled.Wrapper data-id={dataId} deviceSize={deviceSize}>
      <Styled.FoldableSectionWrapper onClick={toggleOpen}>
        <Styled.Title font="title.h5" dataId={`${dataId}.section-title`}>{title}</Styled.Title>
        { open ? <Styled.ArrowUpIcon /> : <Styled.ArrowDownIcon /> }
      </Styled.FoldableSectionWrapper>
      {open && <Styled.Content>{children}</Styled.Content>}
    </Styled.Wrapper>
  );
});

export {
  FoldableSection
};
