import { useInject } from '@actr/di';
import { AppStore } from '@actr/stores';
import { isMobile } from '@actr/style';
import React, { FC } from 'react';

import * as Styled from './style';

const SkeletonAviaOrder: FC = () => {
  const app = useInject(AppStore);
  const { deviceSize } = app;

  return (
    <Styled.Card deviceSize={deviceSize}>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeletonStaticMargin
          deviceSize={deviceSize}
          height={54}
          width={isMobile(deviceSize) ? '100%' : 416}
        />
        <Styled.SmallTextSkeletonAutoMargin deviceSize={deviceSize} height={40} width={197} />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeleton height={56} width="100%" />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapperSmallMargin>
        <Styled.SmallTextSkeleton height={32} width={141} />
      </Styled.FullSizeWrapperSmallMargin>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeleton height={304} width="100%" />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeleton height={32} width={isMobile(deviceSize) ? 248 : 363} />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeleton height={32} width={isMobile(deviceSize) ? 185 : 273} />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapper>
        <Styled.SmallTextSkeleton height={32} width={isMobile(deviceSize) ? 304 : 355} />
      </Styled.FullSizeWrapper>
      <Styled.FullSizeWrapper>
        { Array.from({ length: 3 }).map((_, ind) => (
          <Styled.SmallTextSkeletonColumn
            key={ind}
            deviceSize={deviceSize}
            height={40}
            width={isMobile(deviceSize) ? '100%' : 273}
          />
        )) }
      </Styled.FullSizeWrapper>
    </Styled.Card>
  );
};

export default SkeletonAviaOrder;
