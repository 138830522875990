import { DeviceSizeProps, DeviceSizes, styleIf, styleOnFirstChild,
  styleOnNotFirstChild, styleOnNotLastChild, styleOnTablet, ThemeColorValueImportance } from '@actr/style';
import * as Ui from '@actr/ui';
import { CarriersLogo as CarriersLogoComponent, MinirulesTags } from '@actr/widgets/src/components/avia/flight';
import styled from 'styled-components';

import { GridCol } from '../../../_common/style';

const columnMainInfoFont = 'paragraph.m.semi';
const columnSubMainInfoFont = 'paragraph.s';

const opacityLevel = 0.4;

interface SizeProps {
  size: DeviceSizes;
}

interface StatusProps {
  isExchanged: boolean;
  isRefunded: boolean;
  isWarning: boolean;
}

export const ColumnTitle = styled(Ui.Text).attrs({
  font: columnSubMainInfoFont,
  color: ThemeColorValueImportance.LIGHTER
})<any>(() => ({}));

export const ColumnMainInfo = styled(Ui.Text).attrs({
  font: columnMainInfoFont,
})<any>(() => ({}));

export const ColumnSubMainInfo = styled(Ui.Text).attrs({
  font: columnSubMainInfoFont,
})<any>(() => ({}));

export const LocatorNumber = styled(Ui.Text).attrs({
  font: 'paragraph.m',
})<any>(() => ({}));

export const Wrapper = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'flex',
  padding: theme.padding.m,
  ...styleOnTablet(deviceSize, {
    flexDirection: 'column'
  }),
  [GridCol]: {
    marginRight: 0,
  },
  ...styleOnTablet(deviceSize, {
    [GridCol]: {
      paddingTop: 0
    }
  })
}));

export const LogoWrapper = styled.div<Pick<StatusProps, 'isRefunded'>>(({ isRefunded }) => ({
  ...styleIf(isRefunded, {
    [GridCol]: {
      opacity: opacityLevel
    },
  })
}));

export const InfoWrapper = styled.div<Pick<StatusProps, 'isRefunded'>>(({ isRefunded }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...styleIf(isRefunded, {
    [GridCol]: {
      ...styleOnNotLastChild({
        ...styleOnFirstChild({
          '& > *': {
            ...styleOnNotLastChild({
              opacity: opacityLevel
            })
          }
        }),
        ...styleOnNotFirstChild({
          opacity: opacityLevel
        }),
      })
    },
    [Delimiter]: {
      opacity: opacityLevel
    }
  })
}));

export const Alert = styled(Ui.Alert)(({ theme }) => ({
  margin: `${theme.padding.x2s} 0`
}));

export const Delimiter = styled.div(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundImage:
  `linear-gradient(to right, ${theme.colors.background.base.light.default} 50%, ${theme.colors.border.base.lighter.default} 0%)`,
  backgroundSize: '10px 1px',
  backgroundRepeat: 'repeat-x',
}));

export const MinirulesTagsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

export const MinirulesTagsContent = styled(MinirulesTags)(({ theme }) => ({
  marginRight: theme.margin.x3s,
  marginTop: theme.margin.x3s,
}));

export const Locator = styled.div<SizeProps & StatusProps>(({ isExchanged, isRefunded, isWarning, theme }) => ({
  padding: `${theme.margin.x2s} ${theme.margin.s}`,
  border: `1px solid ${theme.colors.border.base.lighter.default}`,
  borderRadius: theme.borderRadius.s,
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  ...styleIf(isRefunded, {
    borderColor: theme.colors.border.error.normal.default
  }),
  ...styleIf(isExchanged, {
    borderColor: theme.colors.border.info.normal.default
  }),
  ...styleIf(isWarning, {
    borderColor: theme.colors.border.warning.normal.default
  })
}));

export const CarriersLogo = styled(CarriersLogoComponent)(() => ({
  width: '96px',
  height: '50px',
}));

export const DetailsLink = styled(Ui.Link)(() => ({
}));
