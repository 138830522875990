import { DeviceSizeProps, styleIf, styleOnMobile } from '@actr/style';
import { Link, Text } from '@actr/ui';
import styled from 'styled-components';

export const GeneralInfoTitle = styled(Text)<DeviceSizeProps>(({ deviceSize, theme }) => ({
  marginBottom: theme.margin.x4s,
  ...styleOnMobile(deviceSize, {
    fontSize: theme.fontSize.xs,
  })
}));

export const GeneralInfoLogoColumn = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  width: '72px',
  flexShrink: 0,
  justifyContent: 'flex-start',
  marginRight: theme.margin.s,
  ...styleOnMobile(deviceSize, {
    marginRight: 'auto',
    width: 'auto',
  })
}));

export const GeneralInfoDetailsColumn = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  ...styleOnMobile(deviceSize, {
    padding: `${theme.padding.xs} 0`
  })
}));

export const GeneralInfo = styled.div<DeviceSizeProps & { isRefunded: boolean }>(({ isRefunded, deviceSize }) => ({
  display: 'flex',
  flexDirection: 'row',
  ...styleOnMobile(deviceSize, {
    flexDirection: 'column'
  }),
  ...styleIf(isRefunded, {
    opacity: 0.4
  })
}));

export const InfoModalContent = styled.div(({ theme }) => ({
  padding: `0 ${theme.margin.m}`
}));

export const InfoModalContentTrigger = styled(Link)(() => ({
}));
