import { IconChevronDown } from '@actr/icons/chevron-down';
import { IconChevronUp } from '@actr/icons/chevron-up';
import { DeviceSizeProps, styleOnMinDesktop, styleOnTablet } from '@actr/style';
import { Text } from '@actr/ui';
import styled from 'styled-components';

export const FoldableSectionWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start'
}));

export const Wrapper = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  cursor: 'pointer',
  ...styleOnMinDesktop(deviceSize, {
    marginBottom: theme.margin.m
  }),
  ...styleOnTablet(deviceSize, {
    marginBottom: theme.margin.s
  })
}));

export const ArrowUpIcon = styled(IconChevronUp)(({ theme }) => ({
  marginLeft: theme.margin.x3s,
  fontSize: theme.iconSize.xs
}));

export const ArrowDownIcon = styled(IconChevronDown)(({ theme }) => ({
  marginLeft: theme.margin.x3s,
  fontSize: theme.iconSize.xs
}));

export const Title = styled(Text)(() => ({
}));

export const Content = styled.div(({ theme }) => ({
  paddingTop: theme.margin.xs
}));
