import { useInject } from '@actr/di';
import { orderStatuses, paymentStatusCodes } from '@actr/specs';
import { AccountAviaOrderModelPaymentHistoryItem, AppStore } from '@actr/stores';
import { ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { TagSizes, TagTypes, Text } from '@actr/ui';
import { formatMoney, translate } from '@actr/utilities';
import { DOCUMENTS } from 'Components/_misc/specs';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';

import useOrdersTranslator from '../../../orders/locales';
import { FoldableSection } from '../../_common/foldable-section';
import { AccountAviaOrderSectionProps } from '../specs';
import * as Styled from './style';

const PaymentHistorySection: React.FC<AccountAviaOrderSectionProps> = props => {
  const app = useInject(AppStore);
  const { deviceSize } = app;

  const { dataId, isOpen, order } = props;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${DOCUMENTS}` : DOCUMENTS;

  const currentOrder = order.aviaOrder!;
  const { paymentHistory, currency } = currentOrder;
  const isBooked = currentOrder.orderStatus === orderStatuses.BOOKED;
  const orderEntry = {
    billing_number: currentOrder.billing_number,
    client_comment: t('Оплата заказа'),
    status_code: isBooked ? paymentStatusCodes.STATUS_NEW : currentOrder.payment.last_payment?.status,
    payment: currentOrder.payment,
  } as AccountAviaOrderModelPaymentHistoryItem;

  const fullPaymentHistory = [...paymentHistory, orderEntry];

  function renderStatusTag(status: orderStatuses | paymentStatusCodes) {
    let colorScheme:ThemeColorValueTypes;

    const formattedStatus = status.toLowerCase();

    switch (formattedStatus) {
      case orderStatuses.PAID:
      case orderStatuses.TICKET:
      case paymentStatusCodes.STATUS_PAID:
        colorScheme = ThemeColorValueTypes.SUCCESS;
        break;
      case orderStatuses.BOOKED:
      case orderStatuses.AWAIT_CONFIRMATION_PAYMENT:
      case orderStatuses.REFUND_IN_PROCESS:
      case orderStatuses.WAIT_AUTHORIZATION:
      case orderStatuses.TICKETED_IN_PROVIDER:
      case orderStatuses.REFUND_AUTHORIZED:
      case paymentStatusCodes.STATUS_CHECKED:
      case paymentStatusCodes.STATUS_NEW:
      case paymentStatusCodes.STATUS_WAITING_CALLBACK:
        colorScheme = ThemeColorValueTypes.WARNING;
        break;
      case orderStatuses.CANCELLED:
      case orderStatuses.REFUNDED:
      case paymentStatusCodes.STATUS_CANCELLED:
      case paymentStatusCodes.STATUS_PARTIALLY_REFUNDED:
        colorScheme = ThemeColorValueTypes.ERROR;
        break;
      default:
        colorScheme = ThemeColorValueTypes.INFO;
    }

    return (
      <Styled.PaymentStatusTag dataId={`${dataIdName}.status`} size={TagSizes.XS} tagType={TagTypes.TERTIARY} themeColor={colorScheme}>
        {translate.orderPaymentStatus(formattedStatus as paymentStatusCodes) || translate.orderStatus(formattedStatus as orderStatuses)}
      </Styled.PaymentStatusTag>
    );
  }

  function renderHistoryItem(item: AccountAviaOrderModelPaymentHistoryItem) {
    return (
      <Styled.PaymentHistoryItem deviceSize={deviceSize}>
        <Styled.PaymentHistoryItemTitle font="title.h7" deviceSize={deviceSize}>
          { item.client_comment }
        </Styled.PaymentHistoryItemTitle>
        <Text color={ThemeColorValueImportance.LIGHTER}>
          {`№ ${item.billing_number}`}
        </Text>
        <Styled.PaymentHistoryPrice>
          { formatMoney(item.payment.price[currency]!.amount, currency) }
        </Styled.PaymentHistoryPrice>
        { item.status_code && renderStatusTag(item.status_code) }
      </Styled.PaymentHistoryItem>
    );
  }

  return (
    <FoldableSection title={t('История оплаты')} isInitiallyOpen={isOpen} dataId={dataIdName}>
      <Styled.PaymentHistory>
        {fullPaymentHistory.map((item, idx, array) => (
          <Fragment key={idx}>
            {renderHistoryItem(item)}
            {idx !== array.length - 1 && <Styled.Delimiter />}
          </Fragment>
        ))}
      </Styled.PaymentHistory>
    </FoldableSection>
  );
};

export default observer(PaymentHistorySection);
