import { useInject } from '@actr/di';
import { AppStore, AviaOrderLocatorInfoSegments } from '@actr/stores';
import { AccordionItem, FlexDirection, FlexWrapper } from '@actr/ui';
import { getRouteDuration } from '@actr/widgets/src/components/avia/flight';
import { getFlightRelatedInfo } from '@actr/widgets/src/utils';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';

import RouteInfo from './route';
import * as Styled from './style';

export type FlightInfoModalProps = {
  isOpen: boolean;
  segments: AviaOrderLocatorInfoSegments[];
  onClose: () => void;
  defaultOpenIndex?: number;
};

const FlightInfoModal: FC<FlightInfoModalProps> = props => {
  const { isOpen, onClose, segments, defaultOpenIndex } = props;
  const app = useInject(AppStore);
  const { isMinDesktop, deviceSize } = app;

  const { t, locale } = useOrdersTranslator();

  const accordionItems: AccordionItem[] = useMemo(() => segments.map(segmentsGroup => {
    const firstSegment = segmentsGroup.segmentsNewFormat[0];
    const lastSegment = segmentsGroup.segmentsNewFormat[segmentsGroup.segmentsNewFormat.length - 1];

    return ({
      title: `${firstSegment.departure.city.title} - ${lastSegment.arrival.city.title}`,
      subTitle: `${t('В пути')} ${getRouteDuration(segmentsGroup.segmentsOldFormat[0]).format(t('h[ч] mm[м]'))}`,
      content: (
        <RouteInfo
          segments={segmentsGroup}
          flightInfo={getFlightRelatedInfo(null, segmentsGroup.segmentsNewFormat)}
        />
      )
    });
  }), [locale]);

  return (
    <Styled.Modal
      open={isOpen && !!segments}
      useStickyHeader={true}
      onClose={onClose}
      disableFooterCloseButton={true}
      Title={(
        <FlexWrapper as="span" direction={FlexDirection.COL}>
          <span>{t('Детали перелета')}</span>
        </FlexWrapper>
      )}
      isPopup={isMinDesktop}>
      <Styled.WraperGrid noFlexWrap={isMinDesktop} rowSpacing={null} columnSpacing={null}>
        <Styled.FlightInfoGridCol
          deviceSize={deviceSize}
          spanMobile={12}
          spanTablet={12}
          span={12}
          grow={true}>
          <Styled.Accordion
            scrollToOpenedTab={true}
            items={accordionItems}
            defaultOpenIndex={defaultOpenIndex}
          />
        </Styled.FlightInfoGridCol>
      </Styled.WraperGrid>
    </Styled.Modal>
  );
};

export default observer(FlightInfoModal);
