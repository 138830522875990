import 'moment-duration-format';

import { useInject } from '@actr/di';
import { IconExchange } from '@actr/icons/exchange';
import { IconRefund } from '@actr/icons/refund';
import { IBaseComponentProps } from '@actr/specs';
import { AppStore, AviaFlightSegment, AviaOrderLocatorInfoNewFormatSegment, FlightSegmentModel } from '@actr/stores';
import { ThemeColorValueTypes } from '@actr/style';
import { AlertTypes, Link, Text } from '@actr/ui';
import { FlightRelatedInfo, useAviaFlightSegmentsProcessing } from '@actr/widgets/src/components/avia/flight';
import { ACCOUNT_AVIA_ORDER, FLIGHT } from 'Components/_misc/specs';
import { OrderAlertContent } from 'Components/account/main-pages/_common/order-alert-content';
import useOrdersTranslator from 'Components/account/main-pages/v2orders/locales';
import config from 'Configs';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import * as CommonStyled from '../../../_common/style';
import { AccountAviaOrderSectionProps } from '../../specs';
import { AlertNotification, AlertSegmentChangeTypes } from './specs';
import * as Styled from './styles';

export function formatDate(date: string) {
  return moment(date, config.dateTime.serverDateFormat).format('D MMMM, dd').toLowerCase().replace('.', '');
}

function getTariffName(segments: AviaFlightSegment[]) {
  return uniq(segments.map(segment => segment.tariff_name)).join(', ');
}
export interface FlightProps extends IBaseComponentProps, Omit<AccountAviaOrderSectionProps, 'flight' | 'order'> {
  segments: AviaOrderLocatorInfoNewFormatSegment[];
  oldFormatSegments: FlightSegmentModel[];
  showDelimiter?: boolean;
  onOpenDetails: (passengerId: number, segmentGroupIdx: number) => void;
  flightInfo: FlightRelatedInfo;
  showUnticketedInfo: boolean;
  passengerId: number;
}

const Flight: React.FC<FlightProps> = observer(props => {
  const {
    segments,
    passengerId,
    oldFormatSegments,
    flightInfo,
    className,
    onOpenDetails,
    showDelimiter = true,
    showUnticketedInfo
  } = props;

  const app = useInject(AppStore);
  const { deviceSize } = app;

  const { t } = useOrdersTranslator();

  const { carriers, flightDuration } = useAviaFlightSegmentsProcessing({
    segments: oldFormatSegments
  }, flightInfo);

  const isExchanged = segments.every(segment => segment.isExchanged);
  const hasExchangedSegment = !isExchanged && segments.some(segment => segment.isExchanged);
  const isRefunded = segments.every(segment => segment.isRefunded);
  const hasRefundedSegment = !isRefunded && segments.some(segment => segment.isRefunded);

  const isUnticketed = showUnticketedInfo && segments.every(segment => !segment.isTicketed);
  const hasUnticketedSegment = showUnticketedInfo && !isUnticketed && segments.some(segment => !segment.isTicketed);

  const dataIdName = `${ACCOUNT_AVIA_ORDER}.${FLIGHT}`;
  const showAlert = hasExchangedSegment || hasRefundedSegment || isExchanged || isRefunded;

  function handleOpenDetails() {
    onOpenDetails(passengerId, segments[0].id);
  }

  function renderCarriersLogo() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.carriers-column`}>
        <Styled.CarriersLogo carriers={carriers} />
      </CommonStyled.GridCol>
    );
  }

  function renderFlightWayText() {
    const firstSegment = segments[0];
    const lastSegment = last(segments);

    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.flight-column`}>
        <Styled.ColumnTitle>{t('Направление')}</Styled.ColumnTitle>
        <Styled.ColumnMainInfo>
          <span data-id={`${dataIdName}.departure-city`}>{firstSegment.departure.city.title}</span>
          {' - '}
          <span data-id={`${dataIdName}.arrival-city`}>{lastSegment?.arrival.city.title}</span>
        </Styled.ColumnMainInfo>
        <Styled.ColumnSubMainInfo dataId={`${dataIdName}.departure-date`}>
          {formatDate(firstSegment.departure.datetime)}
        </Styled.ColumnSubMainInfo>
        <Styled.DetailsLink onClick={handleOpenDetails}>
          <Text font="paragraph.xs.semi" colorType={ThemeColorValueTypes.INFO} data-id={`${dataIdName}.open-details-trigger`}>
            {t('Подробнее')}
          </Text>
        </Styled.DetailsLink>
      </CommonStyled.GridCol>
    );
  }

  function renderPathInfo() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.path-column`}>
        <Styled.ColumnTitle>{t('В пути')}</Styled.ColumnTitle>
        <Styled.ColumnMainInfo dataId={`${dataIdName}.duration`}>
          { flightDuration.format(t('d[д] h[ч] mm[м]')) }
        </Styled.ColumnMainInfo>
        <Styled.ColumnSubMainInfo dataId={`${dataIdName}.transfers`}>
          { segments.length === 1
            ? t('Без пересадок')
            : `${segments.length - 1} ${t('пересадка', { count: segments.length - 1 })}`}
        </Styled.ColumnSubMainInfo>
      </CommonStyled.GridCol>
    );
  }

  function renderTariff() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.tariff-column`}>
        <Styled.ColumnTitle>{t('Тариф')}</Styled.ColumnTitle>
        <Styled.ColumnMainInfo dataId={`${dataIdName}.tariff-name`}>
          {getTariffName(segments)}
        </Styled.ColumnMainInfo>
        <Styled.MinirulesTagsWrapper data-id={`${dataIdName}.mini-rules`}>
          <Styled.MinirulesTagsContent segments={oldFormatSegments} small={true} flightInfo={flightInfo} />
        </Styled.MinirulesTagsWrapper>
      </CommonStyled.GridCol>
    );
  }

  function renderLocatorTitle() {
    if (isRefunded) {
      return (
        <Styled.ColumnSubMainInfo colorType={ThemeColorValueTypes.ERROR}>
          {t('Возврат направления')}
        </Styled.ColumnSubMainInfo>
      );
    }
    if (hasRefundedSegment) {
      return (
        <Styled.ColumnSubMainInfo colorType={ThemeColorValueTypes.WARNING}>
          {t('Возврат сегмента')}
        </Styled.ColumnSubMainInfo>
      );
    }
    if (isExchanged || hasExchangedSegment) {
      return (
        <Styled.ColumnSubMainInfo colorType={ThemeColorValueTypes.INFO}>
          {isExchanged ? t('Обмен направления') : t('Обмен сегмента')}
        </Styled.ColumnSubMainInfo>
      );
    }
    if (isUnticketed || hasUnticketedSegment) {
      return (
        <Styled.ColumnSubMainInfo colorType={ThemeColorValueTypes.WARNING}>
          {isUnticketed ? t('Направление не выписано') : t('Сегмент не выписан')}
        </Styled.ColumnSubMainInfo>
      );
    }
    return null;
  }

  function renderLocator() {
    return (
      <CommonStyled.GridCol withoutPadding={true} data-id={`${dataIdName}.locator-column`}>
        <Styled.Locator
          isRefunded={isRefunded}
          isWarning={hasRefundedSegment || isUnticketed || hasUnticketedSegment}
          size={deviceSize}
          isExchanged={isExchanged || hasExchangedSegment}>
          {renderLocatorTitle()}
          <Styled.ColumnTitle>{(t('Номер бронирования'))}</Styled.ColumnTitle>
          <Styled.LocatorNumber data-id={`${dataIdName}.locator-number`}>
            {segments[0].related_ticket_locator}
          </Styled.LocatorNumber>
        </Styled.Locator>
      </CommonStyled.GridCol>
    );
  }

  function renderAlerts() {
    let notifications:AlertNotification[] = [];

    if (isExchanged) {
      const firstSegment = segments[0];
      const lastSegment = last(segments);

      notifications = [{
        title: `${t('Обмен направления')} ${firstSegment.departure.city.title} – ${lastSegment?.arrival.city.title}`,
        segmentChangeType: AlertSegmentChangeTypes.EXCHANGED
      }];
    }
    if (hasExchangedSegment) {
      notifications = segments.filter(segment => segment.isExchanged).map(segment => ({
        title: `${t('Обмен сегмента')} ${segment.departure.city.title} – ${segment?.arrival.city.title}`,
        segmentChangeType: AlertSegmentChangeTypes.EXCHANGED
      }));
    }

    if (isRefunded) {
      const firstSegment = segments[0];
      const lastSegment = last(segments);

      notifications = [{
        title: `${t('Возврат направления')} ${firstSegment.departure.city.title} – ${lastSegment?.arrival.city.title}`,
        segmentChangeType: AlertSegmentChangeTypes.REFUNDED,
        type: AlertTypes.DANGER
      }];
    }
    if (hasRefundedSegment) {
      notifications = segments.filter(segment => segment.isExchanged).map(segment => ({
        title: `${t('Возврат сегмента')} ${segment.departure.city.title} – ${segment?.arrival.city.title}`,
        segmentChangeType: AlertSegmentChangeTypes.REFUNDED,
        type: AlertTypes.WARNING
      }));
    }
    return (
      notifications.map(notification => (
        <Styled.Alert
          icon={notification.segmentChangeType === AlertSegmentChangeTypes.EXCHANGED ? <IconExchange /> : <IconRefund />}
          type={notification.type}>
          <OrderAlertContent
            leftSide={notification.title}
            rightSide={<Link onClick={handleOpenDetails}>{t('Подробнее')}</Link>}
          />
        </Styled.Alert>
      ))
    );
  }

  return (
    <>
      {showDelimiter && <Styled.Delimiter />}
      <Styled.Wrapper className={className} deviceSize={deviceSize} data-id={dataIdName}>
        <Styled.LogoWrapper isRefunded={isRefunded}>
          {renderCarriersLogo()}
        </Styled.LogoWrapper>
        <Styled.InfoWrapper isRefunded={isRefunded}>
          <CommonStyled.Info deviceSize={deviceSize}>
            {renderFlightWayText()}
            {renderPathInfo()}
            {renderTariff()}
            {renderLocator()}
          </CommonStyled.Info>
          {showAlert && renderAlerts()}
        </Styled.InfoWrapper>
      </Styled.Wrapper>
    </>
  );

});

export default Flight;
