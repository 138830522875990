import { IconStrokeWidth } from '@actr/icons';
import { IconDocument } from '@actr/icons/document';
import { IconDownloadFile } from '@actr/icons/download-file';
import { styleIf, ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { Button } from '@actr/ui';
import styled from 'styled-components';

export const Documents = styled.div<{ hasReceiptsLocalized?: boolean }>(({ theme, hasReceiptsLocalized }) => ({
  display: 'flex',
  gap: theme.padding.x2s,
  flexWrap: 'wrap',
  marginTop: theme.padding.x2s,
  ...styleIf(!!hasReceiptsLocalized, {
    flexDirection: 'column',
    gap: theme.padding.s,
    marginTop: theme.padding.x3s
  })
}));

export const Ticket = styled(Button)(({ theme }) => ({
  display: 'flex-item',
  flexShrink: 0,
  border: `1px solid ${theme.colors.border.base.normal.default}`,
  padding: theme.padding.s,
}));

export const DocumentIcon = styled(IconDocument).attrs(({
  strokeWidth: IconStrokeWidth.LIGHT,
  colorType: ThemeColorValueTypes.CONTRAST,
}))(() => ({
  height: '40px',
  width: '40px',
  maxHeight: '40px',
  maxWidth: '40px',
}));

export const TicketText = styled.div(({ theme }) => ({
  padding: `0 ${theme.padding.s}`
}));

export const DownloadIcon = styled(IconDownloadFile).attrs(({
  strokeWidth: IconStrokeWidth.BOLD,
  colorType: ThemeColorValueTypes.CONTRAST,
  color: ThemeColorValueImportance.DARKER
}))(() => ({
}));

export const TicketsLang = styled.div(({ theme }) => ({
  fontSize: theme.fontSize.xs,
  color: theme.colors.text.base.lighter.default
}));
