import config from '@actr/config';
import {
  AviaOrderLocatorGroups, AviaOrderLocatorInfo,
  AviaOrderLocatorInfoPassenger,
  AviaOrderUniquePassenger
} from '@actr/stores';
import { getFlightRelatedInfo } from '@actr/widgets/src/utils';
import { PASSENGERS } from 'Components/_misc/specs';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import useOrdersTranslator from '../../../orders/locales';
import { FoldableSection } from '../../_common/foldable-section';
import { AccountAviaOrderSectionProps } from '../specs';
import FlightInfoModal from './flight-info-modal';
import * as Styled from './style';

const Passengers: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId, order, isOpen } = props;
  const currentOrder = order.aviaOrder!;
  const { flight } = currentOrder;
  const { aviaFlightLocatorGroups } = order;

  const hasSomeLocators = (aviaFlightLocatorGroups && aviaFlightLocatorGroups.locators.length > 1) || false;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${PASSENGERS}` : PASSENGERS;

  const showUnticketedInfo = config.avia.unsuccessfulTicketingRefund.enableRefund
    && !!currentOrder.unsuccessful_ticketing && !currentOrder.unsuccessful_ticketing.resolved_at;

  const [isOpenFlightInfoModal, setIsOpenFlightInfoModal] = useState(false);

  const [currentFlightInfoModalData, setCurrentFlightInfoModalData]
    = useState<{passengerId: number; segmentId: number} | undefined>(undefined);

  function handleOpenFlightInfoModal(passengerId: number, segmentId: number) {
    setIsOpenFlightInfoModal(true);
    setCurrentFlightInfoModalData({
      passengerId,
      segmentId
    });
  }

  function handleCloseFlightInfoModal() {
    setIsOpenFlightInfoModal(false);
  }

  function renderPassenger(passenger: AviaOrderUniquePassenger) {
    return (
      <Styled.Passenger passenger={passenger} />
    );
  }

  function renderPassengers(locatorGroups: AviaOrderLocatorGroups) {
    const { passengers } = locatorGroups;

    return passengers.map(passenger => renderPassenger(passenger));
  }

  function renderPassengersForLocator(locatorInfo: AviaOrderLocatorInfo) {
    if (!aviaFlightLocatorGroups?.passengers) {
      return null;
    }

    return (
      <>
        { uniqBy(locatorInfo.passengers, 'passenger.id')
          .map(passenger => passenger && renderPassenger(passenger.passenger)) }
      </>
    );
  }

  function renderFlightInfoModal() {
    const passengerId = currentFlightInfoModalData?.passengerId;
    const passengerSegments = passengerId
      ? aviaFlightLocatorGroups?.locators.map(locator => locator.passengers.filter(
        passenger => passenger.passenger.id === passengerId
      ).map(passenger => passenger.segments)).flat(2)
      : undefined;
    const openIndex = passengerSegments?.findIndex(
      segmentsGroup => segmentsGroup.segmentsNewFormat.some(segment => segment.id === currentFlightInfoModalData?.segmentId)
    );

    if (!passengerId || !passengerSegments) return null;

    return (
      <FlightInfoModal
        isOpen={isOpenFlightInfoModal}
        segments={passengerSegments!}
        defaultOpenIndex={openIndex}
        onClose={handleCloseFlightInfoModal}
      />
    );
  }

  function renderFlight(passenger: AviaOrderLocatorInfoPassenger) {
    const { passenger: passengerInfo, segments } = passenger;

    return segments.map((segmentsGroup, idx: number) => (
      <Styled.Flight
        dataId={`${dataIdName}.ticket-info`}
        key={idx}
        passengerId={passengerInfo.id}
        onOpenDetails={handleOpenFlightInfoModal}
        segments={segmentsGroup.segmentsNewFormat}
        oldFormatSegments={segmentsGroup.segmentsOldFormat}
        flightInfo={getFlightRelatedInfo(flight, segmentsGroup.segmentsNewFormat)}
        showUnticketedInfo={showUnticketedInfo}
      />
    ));
  }

  const showPassengersTogether = aviaFlightLocatorGroups
    && (
      aviaFlightLocatorGroups.passengers.length === 1
      || (
        aviaFlightLocatorGroups.passengers.every(locatorPassenger => !locatorPassenger.isTicketChanged
      && aviaFlightLocatorGroups.locators.every(locator => locator.hasEveryPassenger)
      /* Пока нет поля, что у пассажира были обменяны сегменты, а в exchangeable_segments
        отображаются айди сегментов в процессе обмена сравниваем айди сегментов у пассажиров
        (айди сегментов пассажира для конкретного локатора), чтобы определить показывать ли их вместе */
      && aviaFlightLocatorGroups.locators.every(locator => locator.passengers.every(
        locatorGroupPassenger => isEqual(locatorGroupPassenger.segments_ids, locator.passengers[0].segments_ids)
      ))))
    );

  function renderContent() {
    if (aviaFlightLocatorGroups && hasSomeLocators) {

      return (
        <Styled.Block>
          { aviaFlightLocatorGroups.locators.map(locatorGroup => (
            <Styled.FullLocatorInfo>
              { renderPassengersForLocator(locatorGroup) }
              { renderFlight(locatorGroup.passengers[0]) }
            </Styled.FullLocatorInfo>
          ))}
        </Styled.Block>
      );
    }

    return (
      <div>
        { showPassengersTogether && (
          <Styled.Block>
            { renderPassengers(aviaFlightLocatorGroups) }
            { aviaFlightLocatorGroups.locators.map(locatorGroup => (
              renderFlight(locatorGroup.passengers[0])
            ))}
          </Styled.Block>
        )}
        { aviaFlightLocatorGroups && !showPassengersTogether && aviaFlightLocatorGroups.passengers.map(passenger => (
          <Styled.Block>
            { renderPassenger(passenger) }
            { aviaFlightLocatorGroups.locators.map(locator => locator.passengers
              .filter(locatorPassenger => locatorPassenger.passenger.id === passenger.id)
              .map(locatorPassenger => renderFlight(locatorPassenger))) }
          </Styled.Block>
        ))}
      </div>
    );
  }

  return (
    <FoldableSection title={t('Пассажиры')} isInitiallyOpen={isOpen} dataId={dataIdName}>
      {renderContent()}
      {renderFlightInfoModal()}
    </FoldableSection>
  );
};

export default observer(Passengers);
