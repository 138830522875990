import { useInject } from '@actr/di';
import { AviaPostBookingProductsTypes, aviaSurchageTypes, LocaleToLang } from '@actr/specs';
import { AppStore, OrdersService, PostSaleOrder, Store } from '@actr/stores';
import AccountAviaUnsuccessfulTicketingManager from '@actr/stores/src/stores/stores/account/avia/unsuccessful-ticketing';
import { ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { AlertTypes, FlexAlight, GridCol, GridRow, TextAlight } from '@actr/ui';
import {
  AviaOrderModalOptionsLogs,
  aviaOrderOptionsModalLog,
  aviaOrderUnsuccessfulTicketingLog,
  AviaOrderUnsuccessfulTicketingLogs
} from '@actr/widgets';
import Timer from 'Components/account/main-pages/_common/timer';
import config from 'Configs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { useInstance } from 'react-ioc';

import useOrdersTranslator from '../../../orders/locales';
import * as SectionStyled from '../../_common/foldable-section/style';
import { AccountOrderCardSurchargeProps } from '../../_common/specs';
import { AccountAviaOrderSectionProps } from '../specs';
import * as Styled from './style';

export type NotificatioinsProps = AccountAviaOrderSectionProps & AccountOrderCardSurchargeProps;

const Notifications: React.FC<NotificatioinsProps> = props => {
  const { accountAviaOrderOptions } = useInstance(Store);
  const accountAviaUnsuccessfulTicketingManager = useInject(AccountAviaUnsuccessfulTicketingManager);
  const ordersService = useInstance(OrdersService);
  const app = useInject(AppStore);
  const { deviceSize, isTablet, locale } = app;

  const { order, onOpenPayment } = props;

  const { t } = useOrdersTranslator();

  const currentOrder = order.aviaOrder!;

  const { surchargeOrders } = currentOrder;

  if (!surchargeOrders?.length) {
    return null;
  }

  function defineSurchargeProductType(type: AviaPostBookingProductsTypes): string {
    const modalTitles = {
      [AviaPostBookingProductsTypes.AEROEXPRESS]: t('Аэроэкспресс'),
      [AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS]: t('Дополнительная услуга'),
      [AviaPostBookingProductsTypes.ADDITIONAL_SERVICE]: t('Дополнительная услуга'),
      [AviaPostBookingProductsTypes.INSURANCE]: t('Страховка')
    };

    return modalTitles[type];
  }

  function getSurchargeAlertTitle(surchargeOrder: PostSaleOrder) {
    let title = '';

    const { related_ticket_locator, related_segment } = surchargeOrder;

    if (related_ticket_locator || related_segment) {
      if (related_ticket_locator) {
        const segments = order.aviaFlightLocatorGroups!.locators
          .find(locatorGroup => locatorGroup.locatorId === related_ticket_locator)?.segments;
        title = segments ? `${t('Рейс ')} ${segments[0].departure.city} - ${segments[segments.length - 1].arrival.city}` : '';
      } else {
        const segment = currentOrder.flight.segments.find(flightSegment => flightSegment.id === related_segment);
        title = segment ? `${t('Рейс ')} ${segment?.departure.city} - ${segment?.arrival.city}` : '';
      }
    }

    if ([aviaSurchageTypes.PRODUCTS_POST_SALE, aviaSurchageTypes.POST_SALE].includes(surchargeOrder.type)
      && surchargeOrder.meta.products.length) {
      title = t('У вас есть неоплаченные услуги');
    }

    return title;
  }

  function getSurchargeContent(surchargeOrder: PostSaleOrder): ReactNode {
    if ([aviaSurchageTypes.PRODUCTS_POST_SALE, aviaSurchageTypes.POST_SALE].includes(surchargeOrder.type)
      && surchargeOrder.meta.products.length) {
      return (
        <Styled.ProductsList>
          {surchargeOrder.meta.products.map(product => (
            <span>
              {`${defineSurchargeProductType(product.type)} ${product.title ? `${product.title}` : ''}`}
            </span>
          ))}
        </Styled.ProductsList>
      );
    }

    return null;
  }

  // HANDLERS
  function handleOpenOrderExchangeOptions() {
    aviaOrderOptionsModalLog.send(AviaOrderModalOptionsLogs.CLICK_OPEN_OPTIONS_MODAL);

    runInAction(() => {
      accountAviaOrderOptions.setOrder(currentOrder);
      accountAviaOrderOptions.exchangeManager.showModal = true;
    });
  }

  function handleOpenOrderTicketing() {
    aviaOrderUnsuccessfulTicketingLog.send(AviaOrderUnsuccessfulTicketingLogs.OPEN_MODAL_FROM_NOTIFICATION);

    runInAction(() => {
      accountAviaUnsuccessfulTicketingManager.setOrder(currentOrder);
      accountAviaUnsuccessfulTicketingManager.toggleIsOpenModal();
    });
  }

  function handlePay(surchargeOrder: PostSaleOrder) {
    if ([aviaSurchageTypes.SURCHARGE_CHANGE].includes(surchargeOrder.type)) {
      handleOpenOrderExchangeOptions();
      return;
    }

    if ([aviaSurchageTypes.TICKETING_REQUEST].includes(surchargeOrder.type)) {
      handleOpenOrderTicketing();
      return;
    }

    onOpenPayment({
      billing_number: surchargeOrder.billing_number,
      sig: surchargeOrder.sig,
      payment_info_id: surchargeOrder.payment_info_id
    });
  }

  function updateOrder() {
    ordersService.updateV2Order(order);
  }

  function renderTimeLimit(surchargeOrder: PostSaleOrder) {
    if (!surchargeOrder.expire_remain) return null;
    const date = moment(surchargeOrder.expire, config.dateTime.serverDateTimeFormat);

    return (
      <GridRow noFlexWrap={!isTablet}>
        <GridCol span={9} alight={isTablet ? FlexAlight.START : FlexAlight.END}>
          <Styled.TimeLimitText
            align={isTablet ? TextAlight.LEFT : TextAlight.RIGHT}
            color={ThemeColorValueImportance.LIGHTER}
            colorType={ThemeColorValueTypes.ERROR}
            deviceSize={deviceSize}>
            {t('Оплатить до')}
            {' '}
            {date.locale(LocaleToLang[locale]).format('DD MMMM YYYY г.')}
            {`, ${date.locale(LocaleToLang[locale]).format('HH:mm')} ${t('по Московскому времени')} (UTC +3)`}
          </Styled.TimeLimitText>
          <Timer expireRemain={surchargeOrder.expire_remain} onFinish={updateOrder} />
        </GridCol>
      </GridRow>
    );
  }

  function renderNotification(surchargeOrder: PostSaleOrder) {
    const title = getSurchargeAlertTitle(surchargeOrder);

    return (
      <Styled.Alert
        type={AlertTypes.WARNING}
        title={title || surchargeOrder.client_comment}>
        <Styled.NotificationContent deviceSize={deviceSize}>
          <Styled.NotificationMainInfo>
            <Styled.AlertComment>
              { title && surchargeOrder.client_comment }
            </Styled.AlertComment>
            { getSurchargeContent(surchargeOrder) }
            <Styled.PayButton onClick={() => handlePay(surchargeOrder)}>
              {t('Оплатить')}
              <Styled.Price extendColor={true} amount={surchargeOrder.payment.price.RUB!.amount} />
            </Styled.PayButton>
          </Styled.NotificationMainInfo>
          { renderTimeLimit(surchargeOrder) }
        </Styled.NotificationContent>
      </Styled.Alert>
    );
  }

  return (
    <SectionStyled.Wrapper deviceSize={deviceSize}>
      { surchargeOrders.map(renderNotification) }
    </SectionStyled.Wrapper>
  );
};

export default observer(Notifications);
