import { ALERTS } from 'Components/_misc/specs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AccountAviaOrderSectionProps } from '../specs';
import UnticketedFlightsAlerts from './unticketed';

const Alerts: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId } = props;
  const dataIdName = dataId ? `${dataId}.${ALERTS}` : ALERTS;

  return (
    <UnticketedFlightsAlerts {...props} dataId={dataIdName} />
  );
};

export default observer(Alerts);
