import config from '@actr/config';
import { useInject } from '@actr/di';
import { AviaOrderExchangeRequestStatusType, AviaOrderRefundRequestStatusType } from '@actr/specs';
import {
  AccountAviaExchangeRequestStore,
  AccountAviaRefundManager, AccountAviaRefundRequestStore,
  AppStore,
  Store,
  TAviaOrderExchangeRequestModel,
  TAviaOrderRefundRequestModel,
  V2OrderStore
} from '@actr/stores';
import { AutotestSupport, ProgressBar, TagSizes, Text } from '@actr/ui';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { FoldableSection } from 'Components/account/main-pages/v2orders/_common/foldable-section';
import {
  exchangeRequestStatusFormatter,
  EXCHANGES_AND_RETURNS_HISTORY,
  exchangeSubTypeFormatter,
  refundRequestStatusFormatter,
  refundSubTypeFormatter,
  RequestTypes,
  typeFormatter
} from 'Components/account/main-pages/v2orders/avia/requests-history/specs';
import useOrderRequests from 'Hooks/account/orders/use-order-requests';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC } from 'react';
import { useInstance } from 'react-ioc';

import * as Styled from './style';

interface RequestsHistorySectionProps extends AutotestSupport{
  order: V2OrderStore;
  isOpen: boolean;
}

const RequestsHistorySection: FC<RequestsHistorySectionProps> = props => {
  const { dataId, isOpen, order } = props;
  const { orderInfo: { exchange_request, refund_request } } = order;
  const { requestsList, updateList } = useOrderRequests(order);
  const app = useInject(AppStore);
  const { deviceSize } = app;

  const { accountAviaOrderOptions } = useInstance(Store);
  const { exchangeManager } = accountAviaOrderOptions;
  const refundManager = useInject(AccountAviaRefundManager);

  const isOpenRefundModal = refundManager.showModal;
  const isOpenExchangeModal = exchangeManager.showModal;
  const getOrderRefundRequestsAction = refundManager.getServerAction('getOrderRefundRequests');
  const getOrderExchangeRequestsAction = exchangeManager.getServerAction('getOrderExchangeRequests');

  const { t } = useOrdersTranslator();

  const dataIdName = dataId ? `${dataId}.${EXCHANGES_AND_RETURNS_HISTORY}` : EXCHANGES_AND_RETURNS_HISTORY;

  function handleOpenRequestInfo(
    type: RequestTypes,
    item: AccountAviaRefundRequestStore | AccountAviaExchangeRequestStore,
    _isOpen?: boolean
  ) {
    runInAction(() => {
      accountAviaOrderOptions.setOrder(order.orderInfo);
      if (type === RequestTypes.REFUND_REQUEST) {
        accountAviaOrderOptions.refundManager.setRequest(item as AccountAviaRefundRequestStore);
        refundManager.showModal = _isOpen ?? !isOpenRefundModal;
      }
      if (type === RequestTypes.EXCHANGE_REQUEST) {
        accountAviaOrderOptions.exchangeManager.setRequest(item as AccountAviaExchangeRequestStore);
        exchangeManager.showModal = _isOpen ?? !isOpenExchangeModal;
      }
    });
  }

  function handleListOpen() {
    if (!requestsList?.length) {
      updateList();
    }
  }

  function defineRequestType(request: TAviaOrderExchangeRequestModel | TAviaOrderRefundRequestModel): RequestTypes | undefined {
    if (Object.hasOwn(request, 'refund_type')) {
      return RequestTypes.REFUND_REQUEST;
    }

    if (Object.hasOwn(request, 'exchange_type')) {
      return RequestTypes.EXCHANGE_REQUEST;
    }

    return undefined;
  }

  function renderItem(listItem: AccountAviaRefundRequestStore | AccountAviaExchangeRequestStore) {
    const { request } = listItem;
    const type = defineRequestType(request);

    if (!type) {
      return null;
    }

    const lastStatus = request.statuses[request.statuses.length - 1];
    const { created_date, created_time, type: statusType } = lastStatus;
    const lastStatusDate = moment(`${created_date} ${created_time}`, `${config.dateTime.serverDateTimeFormat}`);
    const formattedType = typeFormatter(type, t);
    const formattedSubType = type === RequestTypes.REFUND_REQUEST
      ? refundSubTypeFormatter((request as TAviaOrderRefundRequestModel).refund_type, t)
      : exchangeSubTypeFormatter((request as TAviaOrderExchangeRequestModel).exchange_type, t);
    const { segments } = request as (TAviaOrderRefundRequestModel | TAviaOrderExchangeRequestModel);
    const statusInfoType = type === RequestTypes.REFUND_REQUEST
      ? refundRequestStatusFormatter(statusType as AviaOrderRefundRequestStatusType, t)
      : exchangeRequestStatusFormatter(statusType as AviaOrderExchangeRequestStatusType, t);

    return (
      <Styled.RequestsHistoryItem deviceSize={deviceSize} onClick={() => handleOpenRequestInfo(type, listItem, true)}>
        <Styled.RequestsHistoryItemTitle font="title.h7" deviceSize={deviceSize}>
          {`${t(formattedType)} ${formattedSubType}`}
        </Styled.RequestsHistoryItemTitle>
        { segments?.length
          && (
          <Styled.RequestsHistoryItemSegments>
            {segments.map(segment => (
              <Styled.RequestsHistoryItemSegment key={segment.id}>
                <Text>{`${segment.dep.city.title} - ${segment.arr.city.title}`}</Text>
                <Styled.LightText>
                  { moment(segment.dep.date, config.dateTime.serverDateFormat).format('DD MMMM') }
                </Styled.LightText>
              </Styled.RequestsHistoryItemSegment>
            ))}
          </Styled.RequestsHistoryItemSegments>
          )}
        <Styled.RequestsHistoryItemStatusInfo>
          <Styled.LightText>{lastStatusDate?.format('DD MMM YYYY, HH:mm')}</Styled.LightText>
          <Styled.RequestsHistoryItemStatusTag size={TagSizes.XS} themeColor={statusInfoType.colorScheme}>
            {statusInfoType.message}
          </Styled.RequestsHistoryItemStatusTag>
          <Styled.RequestsHistoryItemFakeButton>
            <Styled.RequestsHistoryItemFakeButtonIcon />
          </Styled.RequestsHistoryItemFakeButton>
        </Styled.RequestsHistoryItemStatusInfo>
      </Styled.RequestsHistoryItem>
    );
  }

  function renderRequestsList() {
    if (!requestsList?.length) {
      return null;
    }

    return (
      <Styled.RequestsHistory>
        {requestsList?.map((item, ind, array) => (
          <Styled.RequestsHistoryItemWrapper key={`${item.currentStatus.type} ${item.createMoment.format('YYYY-MM-DD HH:mm')}`}>
            { renderItem(item) }
            {ind !== array.length - 1 && <Styled.Delimiter />}
          </Styled.RequestsHistoryItemWrapper>
        ))}
      </Styled.RequestsHistory>
    );
  }

  function renderProgressBar() {
    return (
      <Styled.RequestsHistory>
        <Styled.LightText>
          { t('Загружаем данные') }
        </Styled.LightText>
        <ProgressBar max={100} autoStart={true} intervalsTiming={300} />
      </Styled.RequestsHistory>
    );
  }

  if (!refund_request && !exchange_request) {
    return null;
  }

  return (
    <FoldableSection title={t('История возвратов и обменов')} isInitiallyOpen={isOpen} dataId={dataIdName} onToggle={handleListOpen}>
      { (getOrderRefundRequestsAction.isPending || getOrderExchangeRequestsAction.isPending)
        && renderProgressBar() }
      { requestsList?.length
        && renderRequestsList() }
    </FoldableSection>
  );
};

export default observer(RequestsHistorySection);
