import { DeviceSizeProps, styleOnMinDesktop, styleOnNotLastChild, styleOnTablet } from '@actr/style';
import * as Ui from '@actr/ui';
import { TitleWrapper } from '@actr/ui/src/components/_atoms/alert/style';
import { TextDisplay } from '@actr/ui/src/components/_atoms/text/specs';
import styled from 'styled-components';

export const Alert = styled(Ui.Alert)(({ theme }) => ({
  ...styleOnNotLastChild({
    marginBottom: theme.margin.x2s
  }),
  [TitleWrapper]: {
    marginBottom: theme.margin.x3s
  }
}));

export const AlertComment = styled.div(() => ({
}));

export const NotificationContent = styled.div<DeviceSizeProps>(({ deviceSize }) => ({
  display: 'flex',
  ...styleOnMinDesktop(deviceSize, {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  }),
  ...styleOnTablet(deviceSize, {
    flexDirection: 'column'
  })
}));

export const TimeLimitText = styled(Ui.Text).attrs({
  font: 'paragraph.s',
  extendColor: true,
  display: TextDisplay.BLOCK
})<DeviceSizeProps>(({ deviceSize, theme }) => ({
  color: theme.colors.text.base.lighter.default,
  marginBottom: theme.margin.x2s,
  ...styleOnTablet(deviceSize, {
    marginTop: theme.margin.s
  })
}));

export const NotificationMainInfo = styled.div(() => ({}));

export const PayButton = styled(Ui.Button)(({ theme }) => ({
  height: '40px',
  lineHeight: '40px',
  marginTop: theme.margin.s,
  padding: `${theme.padding.x2s} ${theme.padding.xs}`,
  borderRadius: theme.borderRadius.s,
}));

export const Price = styled(Ui.Price)(({ theme }) => ({
  color: theme.colors.text.staticInverted.normal.default,
  marginLeft: theme.margin.x3s
}));

export const ProductsList = styled.div({
  display: 'flex',
  flexDirection: 'column'
});
