import config from '@actr/config';
import { orderStatuses } from '@actr/specs';
import { V2OrderStore } from '@actr/stores';
import { ACCOUNT, BODY } from 'Components/_misc/specs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import Header from '../header';
import { AccountOrderCardProps } from '../specs';
import * as Styled from './style';

const Card: React.FC<AccountOrderCardProps> = observer(props => {
  const { children, order, className, isAviaCard, ...rest } = props;

  const dataIdName = `${ACCOUNT}.${order.orderType}-order.${BODY}`;

  return (
    <Styled.Body
      data-id={dataIdName}
      orderStatus={order.orderStatus.toLowerCase() as orderStatuses}
      className={className}>
      <Styled.Wrapper>
        <Header
          {...rest}
          order={order as V2OrderStore}
          useDefaultLink={config.lkRedesign.isUseOldAviakassa}
          isUpdating={rest.isUpdating}
          isAviaCard={isAviaCard}
        />
        <Styled.ContentWrapper>
          {children}
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    </Styled.Body>
  );
});

export default Card;
