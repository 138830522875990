import { styleOnNotLastChild } from '@actr/style';
import styled from 'styled-components';

import ContainerCard from '../parts/container-card';

export const Card = styled(ContainerCard)(({ theme }) => ({
  ...styleOnNotLastChild({
    marginBottom: theme.margin.xs
  })
}));
