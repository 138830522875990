import config from '@actr/config';
import { useInject } from '@actr/di';
import { AviaFlightSegment } from '@actr/stores';
import AccountAviaUnsuccessfulTicketingManager from '@actr/stores/src/stores/stores/account/avia/unsuccessful-ticketing';
import { AlertTypes, ButtonTypes } from '@actr/ui';
import { aviaOrderUnsuccessfulTicketingLog, AviaOrderUnsuccessfulTicketingLogs } from '@actr/widgets';
import uniq from 'lodash/uniq';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useOrdersTranslator from '../../../../orders/locales';
import { AccountAviaOrderSectionProps } from '../../specs';
import * as Styled from './style';

const UnticketedFlights: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId, order } = props;

  const currentOrder = order.aviaOrder!;
  const { flight } = currentOrder;
  const { t } = useOrdersTranslator();

  const accountAviaUnsuccessfulTicketingManager = useInject(AccountAviaUnsuccessfulTicketingManager);

  const dataIdName = dataId ? `${dataId}.unticketed` : 'unticketed';

  function handleOpenAlertUnsuccessfulRefund() {
    aviaOrderUnsuccessfulTicketingLog.send(AviaOrderUnsuccessfulTicketingLogs.OPEN_MODAL_ALERT, {
      billingNumber: currentOrder.billing_number,
    });

    runInAction(() => {
      accountAviaUnsuccessfulTicketingManager.setOrder(currentOrder);
      accountAviaUnsuccessfulTicketingManager.toggleIsOpenModal();
    });
  }

  function isSegmentUnticketed(segment: AviaFlightSegment) {
    return currentOrder.unsuccessful_ticketing?.passengers
      .find(notTicketedPassenger => segment.related_passengers_ids.includes(notTicketedPassenger.id));
  }

  function renderAlert() {
    const unsuccessfulTicketing = currentOrder.unsuccessful_ticketing;
    if (!unsuccessfulTicketing) return null;

    const unticketedSegments = flight.segments.filter(segment => isSegmentUnticketed(segment));
    if (!unticketedSegments.length) return null;

    const alerts = uniq(unticketedSegments.map(
      segment => `${segment.departure.city.title} – ${segment.arrival.city.title}`
    ));

    return (
      <Styled.Notification type={AlertTypes.WARNING} title={t('Сегменты не выписаны:')} dataId={dataIdName}>
        {alerts.map(alert => <Styled.AlertText font="paragraph.s">{alert}</Styled.AlertText>)}
        <Styled.RefundButton
          onClick={handleOpenAlertUnsuccessfulRefund}
          buttonType={ButtonTypes.BRAND}>
          {t('Управление заказом')}
        </Styled.RefundButton>
      </Styled.Notification>
    );
  }

  if (config.avia.unsuccessfulTicketingRefund.enableRefund
    && !!currentOrder.unsuccessful_ticketing && !currentOrder.unsuccessful_ticketing.selected_problem_solving_strategy) {
    return (
      <>
        {renderAlert()}
      </>
    );
  }

  return null;
};

export default observer(UnticketedFlights);
