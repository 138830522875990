import { DeviceSizeProps, styleOnDesktopSmall, styleOnLastChild, styleOnMinDesktop, styleOnNotLastChild, styleOnTablet } from '@actr/style';
import * as Ui from '@actr/ui';
import styled from 'styled-components';

export const GridCol = styled(Ui.GridCol)<{withoutPadding?: boolean}>(({ theme, withoutPadding }) => ({
  marginBottom: 0,
  paddingTop: !withoutPadding ? `${theme.margin.x2s}` : 0,
  '&:last-child': {
    marginLeft: 'auto'
  }
}));

export const Info = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  width: '100%',
  ...styleOnMinDesktop(deviceSize, {
    display: 'inline-grid',
    gridTemplateColumns: '1.5fr 1fr 1.5fr minmax(174px, auto)',
    gridColumnGap: theme.margin.l,
    ...styleOnLastChild({
      [GridCol]: {
        marginRight: '0'
      }
    })
  }),
  ...styleOnDesktopSmall(deviceSize, {
    gridColumnGap: theme.margin.m,
  }),
  ...styleOnTablet(deviceSize, {
    flexDirection: 'column',
    [GridCol]: {
      ...styleOnNotLastChild({
        marginBottom: theme.margin.s
      })
    }
  }),
}));
