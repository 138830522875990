import { Tag } from '@actr/ui';
import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  padding: theme.padding.m,
}));

export const TimeTag = styled(Tag)(({ theme }) => ({
  backgroundColor: theme.colors.background.base.light.default,
  border: 'unset',
  color: theme.colors.text.base.normal.default
}));

export const ContentWrapper = styled.div({});
