import aviaLog from 'Logs/avia/base';

export enum AviaOrderLogs {
  GO_TO_AVIA_POSTSALES, // начало оплаты
  HAS_SURCHARGES,
  SURCHARGES_PAYMENT_MODAL_OPENED
}

const aviaOrderLog = aviaLog.extend('order').bindEnum(AviaOrderLogs);

export default aviaOrderLog;
