import config from '@actr/config';
import { useInject } from '@actr/di';
import {
  AccountAviaExchangeRequestStore,
  AccountAviaRefundManager, AccountAviaRefundRequestStore,
  AviaOrderExchangeService,
  AviaOrderRefundService,
  OrderModel,
  Store,
  V2OrderStore
} from '@actr/stores';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useInstance } from 'react-ioc';

export interface UseOrderRequests {
  requestsList: (AccountAviaRefundRequestStore | AccountAviaExchangeRequestStore)[] | null;
  updateList: () => void;
}

function useOrderRequests(order: V2OrderStore): UseOrderRequests {
  const { orderInfo } = order;
  const store = useInstance(Store);
  const { accountAviaOrderOptions } = store;
  const aviaOrderRefundService = useInject(AviaOrderRefundService);
  const aviaOrderExchangeService = useInstance(AviaOrderExchangeService);
  const [requestsList, setRequestsList] = useState<(AccountAviaRefundRequestStore | AccountAviaExchangeRequestStore)[] | null>(null);
  const { exchangeManager } = accountAviaOrderOptions;
  const refundManager = useInject(AccountAviaRefundManager);

  function handleListUpdate() {
    setRequestsList(
      orderBy(compact(
        concat<(AccountAviaRefundRequestStore | AccountAviaExchangeRequestStore)>
          (exchangeManager.orderExchangeRequests, refundManager.orderRefundRequests)
      ), ({ request }) => {
        const lastStatus = request.statuses[request.statuses.length - 1];
        const { created_date, created_time } = lastStatus;

        return moment(`${created_date} ${created_time}`, `${config.dateTime.serverDateTimeFormat}`);
      })
    );
  }

  const updateList = useCallback(() => {
    const orderRefundRequestsPromise = aviaOrderRefundService.fillOrderRefundRequests(orderInfo as OrderModel);
    const orderExchangeRequestsPromise = aviaOrderExchangeService.fillOrderExchangeRequests(orderInfo as OrderModel);

    Promise.allSettled([orderRefundRequestsPromise, orderExchangeRequestsPromise]).then(
      results => {
        const [refundResult, exchangeResult] = results;

        if (refundResult.status === 'fulfilled') {
          const { value } = refundResult;
          if (value.length && value[0].order.billing_number === order.orderInfo.billing_number) {
            handleListUpdate();
          }
        }

        if (exchangeResult.status === 'fulfilled') {
          const { value } = exchangeResult;
          if (exchangeResult.value.length && value[0].order.billing_number === order.orderInfo.billing_number) {
            handleListUpdate();
          }
        }
      }
    );
  }, []);

  return {
    requestsList,
    updateList
  };
}

export default useOrderRequests;
