import { orderStatuses, paymentStatusCodes } from '@actr/specs';
import { OrdersService, PaymentOrderInfo } from '@actr/stores';
import { ACCOUNT_AVIA_ORDER } from 'Components/_misc/specs';
import compact from 'lodash/compact';
import some from 'lodash/some';
import aviaOrderLog, { AviaOrderLogs } from 'Logs/avia/order';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import useOrderExpire from 'Root/hooks/account/orders/use-order-expire';

import { Alerts } from '../_common/alerts';
import Body from '../_common/body';
import { AccountOrderCardProps } from '../_common/specs';
import FlightAlerts from './alerts';
import Notifications from './notifications';
import PaymentModal from './payment-modal';
import * as Styled from './styles';

interface AccountAviaOrderProps extends AccountOrderCardProps {
  orderCheckoutPath: string;
}

export const V2AccountAviaOrder: React.FC<AccountAviaOrderProps> = observer(props => {
  const {
    order,
    ...rest
  } = props;
  const { orderIsUpdating } = useOrderExpire(order);

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const ordersService = useInstance(OrdersService);

  const showSurcharge = ![orderStatuses.CANCELLED, orderStatuses.REFUNDED, orderStatuses.REFUND_IN_PROCESS].includes(order.orderStatus);

  useEffect(() => {
    if (order.hasSurcharges
      && some(
        order.surcharges,
        surcharge => [paymentStatusCodes.STATUS_NEW, paymentStatusCodes.STATUS_CHECKED].includes(surcharge.status_code)
      )) {
      aviaOrderLog.send(AviaOrderLogs.HAS_SURCHARGES, {
        billingNumber: order.orderInfo.billing_number,
        surcharges: compact(order.surcharges.map(surcharge => {
          if (surcharge.expire_remain === 0
            || ![paymentStatusCodes.STATUS_NEW, paymentStatusCodes.STATUS_CHECKED].includes(surcharge.status_code)) return null;
          return ({
            sig: surcharge.sig,
            type: surcharge.type,
            status: surcharge.status_code,
            products: surcharge.meta?.products,
            expire: surcharge.expire,
            price: surcharge.payment.price,
          });
        })),
      });
    }
  }, [showSurcharge]);

  function handleClosePaymentModal() {
    setIsOpenPaymentModal(false);
  }

  function handleOpenPaymentModal(orderInfo: PaymentOrderInfo) {
    setIsOpenPaymentModal(true);
    ordersService.getSurchargePaymentInfo(orderInfo, order);
  }

  return (
    <Body {...rest} order={order} isAviaCard={true} isUpdating={orderIsUpdating}>
      <Alerts order={order} isAviaCard={true} />
      {showSurcharge && <Notifications order={order} onOpenPayment={handleOpenPaymentModal} />}
      <FlightAlerts order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.Passengers order={order} dataId={ACCOUNT_AVIA_ORDER} isOpen={true} />
      <Styled.AdditionalProducts order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.Insurances order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.Documents order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.PaymentHistory order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.RequestsHistory order={order} isOpen={false} dataId={ACCOUNT_AVIA_ORDER} />
      <Styled.Footer order={order} dataId={ACCOUNT_AVIA_ORDER} />
      <PaymentModal order={order} onClose={handleClosePaymentModal} isOpen={isOpenPaymentModal} />
    </Body>
  );

});
