import { useInject } from '@actr/di';
import { AdditionalProductsStatuses, AviaOrderAdditionalServicesPlacePickStatus, AviaPostBookingProductsTypes } from '@actr/specs';
import {
  AppStore,
  AviaOrderAdditionalProductsTexts,
  AviaOrderInsurancesTexts
} from '@actr/stores';
import { Modal, Text } from '@actr/ui';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React, { ReactElement, useState } from 'react';

import { ProductCardTicketInfoStatus } from '../product-info';
import AviaPostBookingAdditionalProductLogo
  from './product-logo';
import * as Styled from './styles';

interface GeneralInfoProps {
  id: string;
  logoUrl?: string;
  logo?: ReactElement;
  productType: AviaPostBookingProductsTypes;
  texts: Partial<Pick<AviaOrderAdditionalProductsTexts, 'title' | 'details' | 'description'>>
    & Partial<Pick<AviaOrderInsurancesTexts, 'text'>>;
  status?: ProductCardTicketInfoStatus;
}

export function renderModalText(text?: string | null) {
  if (text) {
    if (/<\/?[a-z][\s\S]*>/i.test(text)) {
      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }
    return text;
  }

  return null;
}

const GeneralInfo: React.FC<GeneralInfoProps> = observer(props => {
  const { id, logoUrl, logo, productType, status, texts } = props;
  const app = useInject(AppStore);
  const { deviceSize } = app;

  const { t } = useOrdersTranslator();

  const [infoModalOpened, setInfoModalOpened] = useState(false);

  const isRefunded = [AdditionalProductsStatuses.REFUNDED, AviaOrderAdditionalServicesPlacePickStatus.REFUNDED,
    AdditionalProductsStatuses.REFUND_AUTHORIZED].includes(status);

  function defineModalTitle(type: AviaPostBookingProductsTypes): string {
    const modalTitles = {
      [AviaPostBookingProductsTypes.AEROEXPRESS]: t('Информация о тарифе'),
      [AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS]: t('Информация об услуге'),
      [AviaPostBookingProductsTypes.ADDITIONAL_SERVICE]: t('Информация об услуге'),
      [AviaPostBookingProductsTypes.INSURANCE]: t('Информация о страховке')
    };

    return modalTitles[type] || 'Информация';
  }

  function handleDetailClick(e: React.MouseEvent) {
    if (!texts.details) return;
    e.stopPropagation();
    setInfoModalOpened(true);
  }

  function renderLogo() {
    if (logoUrl) {
      return (
        <Styled.GeneralInfoLogoColumn deviceSize={deviceSize}>
          <AviaPostBookingAdditionalProductLogo alt={id} href={logoUrl} productType={productType} />
        </Styled.GeneralInfoLogoColumn>
      );
    }

    if (logo) {
      return (
        <Styled.GeneralInfoLogoColumn deviceSize={deviceSize}>
          {logo}
        </Styled.GeneralInfoLogoColumn>
      );
    }

    return null;
  }

  function renderDescription() {
    return (
      <>
        { (texts.title || productType === AviaPostBookingProductsTypes.AEROEXPRESS)
          && (
          <Styled.GeneralInfoTitle font="title.h6" deviceSize={deviceSize}>
            { texts.title || t('Аэроэкспресс')}
          </Styled.GeneralInfoTitle>
          )}

        { texts.description
          && (
          <Text font="paragraph.s">
            { texts.description.split('\n').map(par => <p key={par}>{par}</p>) }
          </Text>
          )}
        { texts.text
          && (
          <Text font="paragraph.s">
            { texts.text.split('\n').map(par => <p key={par}>{par}</p>) }
          </Text>
          )}
      </>
    );
  }

  function renderDetailsButton() {
    if (texts.details) {
      return (
        <Styled.InfoModalContentTrigger underlined>
          {t('Подробнее')}
        </Styled.InfoModalContentTrigger>
      );
    }

    return null;
  }

  return (
    <Styled.GeneralInfo deviceSize={deviceSize} isRefunded={isRefunded} onClick={handleDetailClick}>
      { renderLogo() }
      <Styled.GeneralInfoDetailsColumn deviceSize={deviceSize}>
        { renderDescription() }
        { renderDetailsButton() }
      </Styled.GeneralInfoDetailsColumn>

      <Modal
        preventClickPropagation={true}
        isPopup={true}
        Title={defineModalTitle(productType)}
        backdropCloseModal={true}
        onClose={() => setInfoModalOpened(false)}
        open={infoModalOpened}>
        <Styled.InfoModalContent>
          <Text font="paragraph.s">
            {renderModalText(texts.details)}
          </Text>
        </Styled.InfoModalContent>
      </Modal>
    </Styled.GeneralInfo>
  );
});

export default GeneralInfo;
