import { Intent } from '@actr/specs';
import { DeviceSizeProps, styleIf, styleOnMinDesktop, styleOnNotLastChild, styleOnTablet } from '@actr/style';
import * as Ui from '@actr/ui';
import { ButtonTypes } from '@actr/ui';
import styled from 'styled-components';

import { ContentWrapper } from '../../_common/body/style';

export const Buttons = styled(ContentWrapper).attrs(props => ({
  disableBorder: true,
  ...props
}))<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',

  ...styleOnTablet(deviceSize, {
    flexDirection: 'column',
    [Button]: {
      width: '100%',
      ...styleOnNotLastChild({
        marginBottom: theme.staticMargin.x3s
      })
    }
  }),
  ...styleOnMinDesktop(deviceSize, {
    [Button]: {
      ...styleOnNotLastChild({
        marginRight: theme.margin.s
      })
    }
  })
}));

export const Button = styled(Ui.Button).attrs(props => ({
  intent: Intent.INFO,
  hasNotification: false,
  buttonType: ButtonTypes.SECONDARY,
  ...props,
}))<{ hasNotification: boolean }>(({ theme, hasNotification }) => ({
  height: 40,
  padding: theme.padding.xs,
  borderRadius: theme.borderRadius.s,

  ...styleIf(!!hasNotification, {
    overflow: 'visible',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: -10,
      right: -10,
      borderRadius: '50%',
      border: `4px solid ${theme.colors.graphic.staticInverted.normal.default}`,
      backgroundColor: theme.colors.surface.brand.normal.default,
      width: 12,
      height: 12,
    }
  })
}));
