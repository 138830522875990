import { AviaOrderAdditionalServicesBaggageStatus,
  AviaOrderAdditionalServicesPlacePickStatus,
  AviaPostBookingProductsTypes } from '@actr/specs';
import { AccountAviaOrderAdditionalServices } from '@actr/stores';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AccountAviaOrderModelProps } from '../../specs';
import GeneralInfo from '../parts/container-card/parts/general-info';
import ProductCardTicketInfo from '../parts/container-card/parts/product-info';
import * as Styled from './styles';

export interface InsuranceCardProps {
  products: AccountAviaOrderAdditionalServices;
}

const AdditionalServiceCards: React.FC<InsuranceCardProps & AccountAviaOrderModelProps> = observer(props => {
  const { products, order } = props;
  const { baggage, place_choice } = products;
  const { t } = useOrdersTranslator();

  if (!baggage?.length && !place_choice?.length) return null;

  const baggageTexts = {
    title: t('Дополнительный багаж'),
    description: t('Дополнительный багаж добавлен к вашему бронированию. '
      + 'Проверьте ограничения на вес и габариты.'),
  };

  const placeTexts = {
    title: t('Выбор места'),
    description: t('Ваши места забронированы. '
      + 'Пожалуйста, проверьте номера мест перед посадкой.')
  };

  return (
    <>
      {place_choice && (
        place_choice.map(placeGroup => (
          <Styled.Card
            generalInfo={(
              <GeneralInfo
                id="place-pick"
                logo={<Styled.PlaceIcon />}
                status={placeGroup.totalStatus}
                texts={placeTexts}
                productType={AviaPostBookingProductsTypes.ADDITIONAL_SERVICE}
              />
            )}
            activePart={(
              <ProductCardTicketInfo
                order={order}
                price={placeGroup.totalPrice}
                status={placeGroup.totalStatus}
                comment={placeGroup.totalStatus === AviaOrderAdditionalServicesPlacePickStatus.TICKETED
                  ? t('За все места') : t('Не действует в этом заказе')}
                passengerPlaceItems={placeGroup.items}
                productType={AviaPostBookingProductsTypes.ADDITIONAL_SERVICE}
              />
            )}
          />
        ))
      )}

      {baggage && (
        baggage.map(baggageGroup => (
          <Styled.Card
            generalInfo={(
              <GeneralInfo
                id="extra-baggage"
                logo={<Styled.BaggageIcon />}
                status={baggageGroup.totalStatus}
                texts={baggageTexts}
                productType={AviaPostBookingProductsTypes.ADDITIONAL_SERVICE}
              />
            )}
            activePart={(
              <ProductCardTicketInfo
                baggageItems={baggageGroup.items}
                order={order}
                price={baggageGroup.totalPrice}
                status={baggageGroup.totalStatus}
                comment={baggageGroup.totalStatus === AviaOrderAdditionalServicesBaggageStatus.Ticketed
                  ? t('За весь багаж') : t('Не действует в этом заказе для пассажиров')}
                productType={AviaPostBookingProductsTypes.ADDITIONAL_SERVICE}
              />
            )}
          />
        ))
      )}
    </>
  );
});

export default AdditionalServiceCards;
