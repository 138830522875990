import * as UI from '@actr/ui';
import styled from 'styled-components';

export const Link = styled(UI.Link)(({ theme }) => ({
  marginBottom: theme.margin.x2s
}));

export const Wrapper = styled.div<any>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.padding.s
}));

export const Button = styled(UI.Button)(() => ({
  display: 'block',
  width: '100%'
}));
