import { useInject } from '@actr/di';
import { AppStore } from '@actr/stores';
import { observer } from 'mobx-react-lite';
import React from 'react';

import * as Styled from './styles';

interface ContainerCardProps {
  activePart: React.ReactNode;
  disabled?: boolean;
  generalInfo: React.ReactNode;
}

const ContainerCard: React.FC<ContainerCardProps> = observer(props => {
  const { activePart, disabled = false, generalInfo } = props;
  const app = useInject(AppStore);
  const { deviceSize } = app;

  return (
    <Styled.ContainerCard disabled={disabled} deviceSize={deviceSize}>
      <Styled.ContainerCardGeneralInfo deviceSize={deviceSize}>
        { generalInfo }
      </Styled.ContainerCardGeneralInfo>
      <Styled.ContainerCardActivePart deviceSize={deviceSize}>
        { activePart }
      </Styled.ContainerCardActivePart>
    </Styled.ContainerCard>
  );
});

export default ContainerCard;
