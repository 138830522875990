import { AviaPostBookingProductsTypes } from '@actr/specs';
import { styleIf } from '@actr/style';
import styled from 'styled-components';

export const ProductLogo = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'top'
});

export const ProductLogoWrapper = styled.div<{type: AviaPostBookingProductsTypes}>(({ type }) => ({
  position: 'relative',
  ...styleIf(type === AviaPostBookingProductsTypes.AEROEXPRESS, {
    width: '70px'
  }),
  ...styleIf(type !== AviaPostBookingProductsTypes.AEROEXPRESS, {
    maxWidth: '72px',
    ...styleIf(type === AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS, {
      height: '60px'
    })
  })
}));
