import { AviaTicketReceipt } from '@actr/stores';
import { ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { ButtonTypes, Text } from '@actr/ui';
import { DOCUMENTS } from 'Components/_misc/specs';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useOrdersTranslator from '../../../orders/locales';
import { FoldableSection } from '../../_common/foldable-section';
import { AccountAviaOrderSectionProps } from '../specs';
import * as Styled from './style';

interface AviaTicketReceiptLocalizedMap {
  [key: string]: AviaTicketReceipt[];
}

const getAviaTicketReceiptLocalizedMap = (tickets_receipts: AviaTicketReceipt[]) => {
  const receiptsLocalized: AviaTicketReceiptLocalizedMap = {};
  tickets_receipts.forEach((receipt: AviaTicketReceipt) => {
    if (receipt.url_localized && receipt.url_localized.length > 0) {
      receipt.url_localized.forEach(localize => {
        if (!localize.url) {
          return;
        }
        const data = {
          url: localize.url,
          locator: receipt.locator
        };
        if (receiptsLocalized[localize.language]) {
          receiptsLocalized[localize.language].push(data);
        } else {
          receiptsLocalized[localize.language] = [data];
        }
      });
    }
  });
  return receiptsLocalized;
};

const DocumentsSection: React.FC<AccountAviaOrderSectionProps> = props => {
  const { dataId, order, isOpen } = props;

  const { t } = useOrdersTranslator();
  const dataIdName = dataId ? `${dataId}.${DOCUMENTS}` : DOCUMENTS;

  const { tickets_receipts } = order.aviaOrder!;

  if (!tickets_receipts?.length || !tickets_receipts.some(ticket => ticket.url)) {
    return null;
  }

  function handleDownload(url: string) {
    window.open(url, '_blank');
  }

  const receiptsLocalized: AviaTicketReceiptLocalizedMap = getAviaTicketReceiptLocalizedMap(tickets_receipts);
  const hasReceiptsLocalized = !isEmpty(receiptsLocalized);

  function renderTicketReceiptMultiLang(lang:string, tickets: AviaTicketReceipt[]) {
    const label = {
      en: t('На английском'),
      ru: t('На русском'),
    }[lang] || lang;
    return (
      <div>
        <Styled.TicketsLang>
          {label}
        </Styled.TicketsLang>
        <Styled.Documents>
          {tickets.map(data => (renderTicketReceipt(lang, data)))}
        </Styled.Documents>
      </div>
    );
  }

  function renderTicketReceipt(language: string, data: AviaTicketReceipt) {
    const { url, locator } = data;
    if (!url) {
      return null;
    }
    const label = t('Маршрутная квитанция');
    return (
      <Styled.Ticket
        leftIcons={<Styled.DocumentIcon />}
        rightIcons={<Styled.DownloadIcon />}
        buttonType={ButtonTypes.GHOST}
        onClick={() => handleDownload(url)}>
        <Styled.TicketText>
          <Text font="paragraph.s" color={ThemeColorValueImportance.LIGHTER} colorType={ThemeColorValueTypes.BASE}>
            {label}
            {' '}
            {language !== 'ru' && (language.toUpperCase())}
          </Text>
          <br />
          <Text font="paragraph.s" colorType={ThemeColorValueTypes.BASE}>
            {locator}
          </Text>
        </Styled.TicketText>
      </Styled.Ticket>
    );
  }

  return (
    <FoldableSection title={t('Документы')} isInitiallyOpen={isOpen} dataId={dataIdName}>
      <Styled.Documents hasReceiptsLocalized={hasReceiptsLocalized}>
        { hasReceiptsLocalized
          ? Object.entries(receiptsLocalized).map(([lang, tickets]) => renderTicketReceiptMultiLang(lang, tickets))
          : tickets_receipts.map(ticket => renderTicketReceipt('ru', ticket)) }
      </Styled.Documents>
    </FoldableSection>
  );
};

export default observer(DocumentsSection);
