import { DeviceSizeProps, styleIf, styleOnLastChild, styleOnMinTablet,
  styleOnMobile, ThemeColorValueTypes } from '@actr/style';
import * as Ui from '@actr/ui';
import styled from 'styled-components';

export const Header = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  marginBottom: theme.margin.m,
  ...styleOnMinTablet(deviceSize, {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }),
  ...styleOnMobile(deviceSize, {
    display: 'flex'
  })
}));

export const HeaderInfo = styled.div<DeviceSizeProps>(({ deviceSize }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  ...styleOnMinTablet(deviceSize, {
    alignItems: 'center'
  }),
  ...styleOnMobile(deviceSize, {
    flexDirection: 'column'
  })
}));

export const Title = styled(Ui.Text)(() => ({
}));

export const TitleWrapper = styled.div<DeviceSizeProps>(({ deviceSize }) => ({
  display: 'flex',
  alignItems: 'center',
  ...styleOnMobile(deviceSize, {
    flexDirection: 'column',
    alignItems: 'flex-start'
  })
}));

export const TitleMainInfo = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  lineHeight: 1.5,
  marginTop: theme.margin.x4s,
  display: 'flex',
  ...styleOnMobile(deviceSize, {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.margin.x3s
  })
}));

export const OrderNumber = styled(Ui.Text)(({ theme }) => ({
  marginRight: theme.margin.x2s
}));

export const OrderDate = styled(Ui.Text)(() => ({
  display: 'block',
}));

export const PayButtonText = styled(Ui.Text)(() => ({
}));

export const StatusTagWrapper = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'inline-flex',
  ...styleOnMobile(deviceSize, {
    flexWrap: 'wrap',
    [OrderStatusTag]: {
      marginBottom: theme.margin.x3s,
      ...styleOnLastChild({
        marginBottom: theme.margin.x2s
      })
    }
  }),
}));

export const Price = styled(Ui.Price)<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'flex',
  ...styleOnMobile(deviceSize, {
    marginLeft: 'unset !important',
    marginBottom: theme.margin.xs
  })
}));

export const PayButton = styled(Ui.Button)<DeviceSizeProps>(({ deviceSize, theme }) => ({
  height: 40,
  lineHeight: 40,
  justifyContent: 'center',
  borderRadius: theme.borderRadius.s,
  ...styleOnMobile(deviceSize, {
    width: '100%'
  }),
  ...styleOnMinTablet(deviceSize, {
    marginLeft: theme.staticMargin.x2s,
    fontSize: theme.fontSize.s
  })
}));

export const HeaderPrice = styled.div<DeviceSizeProps>(({ deviceSize }) => ({
  display: 'flex',
  ...styleOnMinTablet(deviceSize, {
    alignItems: 'center',
  }),
  ...styleOnMobile(deviceSize, {
    display: 'flex',
    flexDirection: 'column'
  })
}));

export const OrderStatusTag = styled(Ui.Tag)<{themeColor: ThemeColorValueTypes}>(({ theme, themeColor }) => ({
  height: theme.fontLineHeight.xl,
  marginRight: theme.staticMargin.x2s,

  backgroundColor: theme.colors.surface[themeColor].light.default,
  borderColor: theme.colors.border[themeColor].light.default,
  color: theme.colors.text[themeColor].normal.default,
  '&, &:focus, &:hover, &:active': {
    backgroundColor: theme.colors.surface[themeColor].light.default,
    borderColor: theme.colors.border[themeColor].light.default,
    color: theme.colors.text[themeColor].normal.hover,
  },
  ...styleIf(themeColor === ThemeColorValueTypes.CONTRAST, {
    backgroundColor: theme.colors.background.base.normal.default,
    borderColor: theme.colors.border[themeColor].lighter.default,
    color: theme.colors.text[themeColor].lighter.default,
    '&, &:focus, &:hover, &:active': {
      backgroundColor: theme.colors.background.base.normal.default,
      borderColor: theme.colors.border[themeColor].lighter.default,
      color: theme.colors.text[themeColor].lighter.default,
    }
  }),
  ...styleIf(themeColor === ThemeColorValueTypes.INFO, {
    backgroundColor: theme.colors.surface[themeColor].lighter.default,
    '&, &:focus, &:hover, &:active': {
      backgroundColor: theme.colors.surface[themeColor].lighter.default
    }
  }),
  ...styleIf(themeColor === ThemeColorValueTypes.BASE, {
    backgroundColor: theme.colors.background[themeColor].lighter.default,
    color: theme.colors.text[themeColor].light.default,
    '&, &:focus, &:hover, &:active': {
      backgroundColor: theme.colors.background[themeColor].lighter.default,
      color: theme.colors.text[themeColor].light.default
    }
  })
}));
