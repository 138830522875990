import { DeviceSizeProps, styleIf, styleOnFirstChild, styleOnMobile, styleOnNotFirstChild,
  styleOnNotLastChild } from '@actr/style';
import { Link, Price, Tag, Text, TextCell, TextCellSizes } from '@actr/ui';
import styled from 'styled-components';

export const SegmentStatusTag = styled(Tag)(({ theme }) => ({
  backgroundColor: theme.colors.surface.error.light.default,
  border: `1px solid ${theme.colors.border.error.light.default}`,
  color: theme.colors.text.error.normal.default,
  height: theme.fontLineHeight.s,
  '&, &:focus, &:hover, &:active': {
    backgroundColor: theme.colors.surface.error.light.default,
    color: theme.colors.text.error.normal.hover,
  }
}));

export const ActivePartInfo = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const DownloadStatusTag = styled.div(({ theme }) => ({
  marginBottom: theme.margin.x4s,
}));

export const DownloadLink = styled(Link)(() => ({
}));

export const ActivePartPrice = styled(Price)<DeviceSizeProps & { isBooked: boolean; isRefunded: boolean}>(
  ({ isBooked, isRefunded, deviceSize, theme }) => ({
    textAlign: 'left',
    fontSize: theme.fontSize.s,
    ...styleIf(isBooked, {
      color: theme.colors.text.error.normal.default
    }),
    ...styleIf(isRefunded, {
      textDecoration: 'line-through'
    }),
    padding: 0,
    ...styleOnMobile(deviceSize, {
      fontSize: theme.fontSize.xs,
    }),
  })
);

export const Comment = styled(Text).attrs({
  font: 'paragraph.s',
})(({ theme }) => ({
  fontSize: theme.fontSize.xs,
  marginTop: theme.margin.x3s,
  marginBottom: theme.margin.xs
}));

export const ActivePartInfoColumn = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const List = styled.ol(({ theme }) => ({
  listStylePosition: 'inside',
  padding: 0,
  margin: 0,
  marginTop: theme.margin.x4s
}));

export const AdditionalServicePassenger = styled(Text).attrs({
  font: 'paragraph.s.semi',
})<any>(() => ({
  display: 'block'
}));

export const DetailsInfoCell = styled(TextCell).attrs({
  size: TextCellSizes.S
})(({ theme }) => ({
  ...styleOnNotLastChild({
    marginBottom: theme.margin.x3s
  })
}));

export const PassengerServiceInfoText = styled(Text).attrs({
  font: 'paragraph.s'
})(() => ({
}));

export const PassengerServiceInfoTextItem = styled.div(() => ({
}));

export const ActivePartText = styled(Text).attrs({
  font: 'paragraph.s',
})<any>(() => ({
}));

export const ActivePart = styled.div<{isRefunded: boolean}>(({ isRefunded }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...styleIf(isRefunded, {
    '& > *': {
      ...styleOnNotFirstChild({
        opacity: 0.4
      }),
      ...styleOnFirstChild({
        '& > *': {
          ...styleOnNotLastChild({
            opacity: 0.4
          })
        }
      })
    }
  })
}));

export const ToggleSwitchWrapper = styled.div``;
