import { useInject } from '@actr/di';
import { passengerGenders } from '@actr/specs/src/passenger-genders';
import { passengerTypes } from '@actr/specs/src/passenger-types';
import { AppStore, AviaOrderUniquePassenger } from '@actr/stores';
import { ThemeColorValueImportance, ThemeColorValueTypes } from '@actr/style';
import { Text } from '@actr/ui';
import { PASSENGER } from 'Components/_misc/specs';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { OrderCardPartVariantsProps } from '../../../orders/_common/specs';
import * as CommonStyled from '../style';
import * as Styled from './styles';

const columnMainInfoFont = 'paragraph.m';
const columnMainInfoBoldFont = 'paragraph.m.semi';
const columnSubMainInfoFont = 'paragraph.s';

export interface Props extends OrderCardPartVariantsProps {
  passenger: AviaOrderUniquePassenger;
  dataId?: string;
}

const Passenger: React.FC<Props> = observer(props => {
  const {
    passenger,
    dataId,
  } = props;

  const app = useInject(AppStore);
  const { deviceSize } = app;
  const { t } = useOrdersTranslator();

  const { isTicketRefunded, ticket_number } = passenger;
  const dataIdName = dataId ? `${dataId}.${PASSENGER}` : PASSENGER;

  const isTicketExchanged = !!passenger.exchangeable_segments.length && !passenger.refunded_segments.length;

  // RENDERERS
  function renderColumnTitle(text: string) {
    return (
      <Text font={columnSubMainInfoFont} color={ThemeColorValueImportance.LIGHTER}>{text}</Text>
    );
  }

  function renderFio() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.name-column`}>
        {renderColumnTitle(t('Имя Фамилия'))}
        <Text font={columnMainInfoBoldFont} dataId={`${dataIdName}.full-name`}>
          <span data-id={`${dataIdName}.firstname`}>{ passenger.name.first }</span>
          { ' ' }
          <span data-id={`${dataIdName}.lastname`}>{ passenger.name.last }</span>
        </Text>
      </CommonStyled.GridCol>
    );
  }

  function renderBirthdate() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.birthday-column`}>
        {renderColumnTitle(t('Дата рождения'))}
        <Text font={columnMainInfoFont} dataId={`${dataIdName}.birth-date`}>
          {passenger.birthday_date}
        </Text>
      </CommonStyled.GridCol>
    );
  }

  function renderDocument() {
    return (
      <CommonStyled.GridCol data-id={`${dataIdName}.document-column`}>
        {renderColumnTitle(t('Номер документа'))}
        <Text font={columnMainInfoFont} dataId={`${dataIdName}.document-number`}>
          {passenger.document_number}
        </Text>
      </CommonStyled.GridCol>
    );
  }

  function renderTicket() {
    return (
      <CommonStyled.GridCol withoutPadding={true} data-id={`${dataIdName}.ticket-column`}>
        {(ticket_number || isTicketRefunded || isTicketExchanged) && (
        <Styled.TicketNumber deviceSize={deviceSize} isExchanged={isTicketExchanged} isRefunded={isTicketRefunded}>
          {isTicketRefunded
            && (
            <Text font={columnSubMainInfoFont} colorType={ThemeColorValueTypes.ERROR}>
              {t('Возврат билета')}
            </Text>
            )}
          {isTicketExchanged
            && (
            <Text font={columnSubMainInfoFont} colorType={ThemeColorValueTypes.INFO}>
              {t('Обмен билета')}
            </Text>
            )}
          {ticket_number && (
            <>
              {renderColumnTitle(t('Номер билета'))}
              <Text font={columnMainInfoFont} data-id={`${dataIdName}.ticket-number`}>{ticket_number}</Text>
            </>
          )}
        </Styled.TicketNumber>
        )}
      </CommonStyled.GridCol>
    );
  }

  function renderPassengerIcon() {
    const type = passenger.type.toUpperCase();
    if (type === passengerTypes.ADULT) {
      return passenger.gender === passengerGenders.MALE
        ? <Styled.GenderMaleIcon dataId={`${dataIdName}.gender`} />
        : <Styled.GenderFemaleIcon dataId={`${dataIdName}.gender`} />;
    }
    if (type === passengerTypes.CHILD) {
      return <Styled.ChildIcon dataId={`${dataIdName}.type`} />;
    }
    if (type === passengerTypes.INFANT) {
      return <Styled.InfantIcon dataId={`${dataIdName}.type`} />;
    }
    return null;
  }

  return (
    <Styled.Wrapper deviceSize={deviceSize}>
      <Styled.PassengerIcon data-id={dataIdName} deviceSize={deviceSize}>
        { renderPassengerIcon() }
      </Styled.PassengerIcon>
      <CommonStyled.Info deviceSize={deviceSize}>
        {renderFio()}
        {renderBirthdate()}
        {renderDocument()}
        {renderTicket()}
      </CommonStyled.Info>
    </Styled.Wrapper>
  );
});

export default Passenger;
