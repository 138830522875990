import * as Ui from '@actr/ui';
import styled from 'styled-components';

export const Alert = styled(Ui.Alert)(({ theme }) => ({
  marginBottom: theme.margin.m
}));

export const AlertText = styled(Ui.Text)(() => ({
  alignItems: 'center',
  display: 'flex'
}));
