import { DeviceSizeProps, styleOnMinDesktop, styleOnMobile } from '@actr/style';
import styled from 'styled-components';

export const ContainerCardActivePart = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  padding: theme.padding.s,
  ...styleOnMinDesktop(deviceSize, {
    width: '290px',
    minWidth: '290px',
  }),
  ...styleOnMobile(deviceSize, {
    width: 'auto'
  })
}));

export const ContainerCardGeneralInfo = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  position: 'relative',
  padding: `${theme.padding.s}`,
  flexGrow: 1,
  '&::before': {
    display: 'block',
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    width: '10px',
    height: '10px',
    backgroundColor: theme.colors.background.base.light.default,
    border: `1px solid ${theme.colors.border.info.light.default}`,
    borderRadius: '50%'
  },
  '&::after': {
    display: 'block',
    content: '""',
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 50%)',
    width: '10px',
    height: '10px',
    backgroundColor: theme.colors.background.base.light.default,
    border: `1px solid ${theme.colors.border.info.light.default}`,
    borderRadius: '50%'
  },
  ...styleOnMobile(deviceSize, {
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      transform: 'translate(calc(-50% - 2px), 50%)',
      width: '10px',
      height: '10px',
      backgroundColor: theme.colors.background.base.light.default,
      border: `1px solid ${theme.colors.border.info.light.default}`,
      borderRadius: '50%'
    },
  })
}));

export const ContainerCard
  = styled.div<{disabled: boolean} & DeviceSizeProps>(({ theme, deviceSize }) => ({
    cursor: 'pointer',
    minHeight: '104px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.margin.x2s,
    [ContainerCardGeneralInfo]: {
      borderTop: `1px solid ${theme.colors.border.info.light.default}`,
      borderBottom: `1px solid ${theme.colors.border.info.light.default}`,
      borderLeft: `1px solid ${theme.colors.border.info.light.default}`,
      borderRight: `1px dashed ${theme.colors.border.info.light.default}`,
      borderTopLeftRadius: theme.borderRadius.m,
      borderBottomLeftRadius: theme.borderRadius.m,
    },
    [ContainerCardActivePart]: {
      border: `1px solid ${theme.colors.border.info.light.default}`,
      borderTopRightRadius: theme.borderRadius.m,
      borderBottomRightRadius: theme.borderRadius.m,
      borderLeft: 'none'
    },
    ...styleOnMobile(deviceSize, {
      flexDirection: 'column',
      [ContainerCardGeneralInfo]: {
        borderTop: `1px solid ${theme.colors.border.info.light.default}`,
        borderBottom: `1px dashed ${theme.colors.border.info.light.default}`,
        borderLeft: `1px solid ${theme.colors.border.info.light.default}`,
        borderRight: `1px solid ${theme.colors.border.info.light.default}`,
        borderTopLeftRadius: theme.borderRadius.m,
        borderTopRightRadius: theme.borderRadius.m,
      },
      [ContainerCardActivePart]: {
        borderLeft: `1px solid ${theme.colors.border.info.light.default}`,
        borderRight: `1px solid ${theme.colors.border.info.light.default}`,
        borderBottom: `1px solid ${theme.colors.border.info.light.default}`,
        borderBottomLeftRadius: theme.borderRadius.m,
        borderBottomRightRadius: theme.borderRadius.m,
      }
    }),
  }));
