import {
  AviaOrderExchangeRequestStatusType, AviaOrderExchangeTypes,
  AviaOrderRefundRequestStatusType,
  AviaOrderRefundTypes
} from '@actr/specs';
import { ThemeColorValueTypes } from '@actr/style/src';

export const EXCHANGES_AND_RETURNS_HISTORY = 'exchanges_and_returns_history';

export enum RequestTypes {
  REFUND_REQUEST = 'refund_request',
  EXCHANGE_REQUEST = 'exchange_request'
}

export const typeFormatter = (type: RequestTypes, t: any) => ({
  [RequestTypes.REFUND_REQUEST]: t('Возврат'),
  [RequestTypes.EXCHANGE_REQUEST]: t('Обмен')
}[type]);

export const refundSubTypeFormatter = (subType: AviaOrderRefundTypes, t: any) => ({
  [AviaOrderRefundTypes.FORCED_REFUND]: t('вынужденный'),
  [AviaOrderRefundTypes.VOLUNTARY_REFUND]: t('добровольный')
}[subType]);

export const exchangeSubTypeFormatter = (subType: AviaOrderExchangeTypes, t: any) => ({
  [AviaOrderExchangeTypes.FORCED_EXCHANGE]: t('вынужденный'),
  [AviaOrderExchangeTypes.VOLUNTARY_EXCHANGE]: t('добровольный')
}[subType]);

export const refundRequestStatusFormatter = (status: AviaOrderRefundRequestStatusType, t: any) => ({
  [AviaOrderRefundRequestStatusType.REFUND_ANSWER_RECEIVED]: { message: t('получен'), colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderRefundRequestStatusType.REFUND_REQUEST_DENIED]: { message: t('отклонен'), colorScheme: ThemeColorValueTypes.ERROR },
  [AviaOrderRefundRequestStatusType.REFUND_REQUEST_EXPIRED]: { message: t('срок истек'), colorScheme: ThemeColorValueTypes.WARNING },
  [AviaOrderRefundRequestStatusType.REFUND_REQUEST_SENT]: { message: t('отправлен'), colorScheme: ThemeColorValueTypes.INFO },
  [AviaOrderRefundRequestStatusType.REFUND_COMPLETED]: { message: t('завершен'), colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderRefundRequestStatusType.REFUND_CONFIRMED]: { message: t('подтвержден'), colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderRefundRequestStatusType.REFUND_REQUEST_NOT_POSSIBLE]:
    { message: 'возврат невозможен', colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderRefundRequestStatusType.WAIVER_OF_REFUND]: { message: t('вы отказались'), colorScheme: ThemeColorValueTypes.ERROR }
}[status]);

export const exchangeRequestStatusFormatter = (status: AviaOrderExchangeRequestStatusType, t: any) => ({
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_ADDITIONAL_SURCHARGE_EXPIRED]:
      { message: t('срок доплаты истек'), colorScheme: ThemeColorValueTypes.ERROR },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_CANCELLED]:
      { message: t('вы отказались'), colorScheme: ThemeColorValueTypes.ERROR },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_COMPLETED]:
      { message: t('завершен'), colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_EXPIRED]:
      { message: t('срок истек'), colorScheme: ThemeColorValueTypes.WARNING },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_NEED_ADDITIONAL_SURCHARGE]:
      { message: t('необходима доплата'), colorScheme: ThemeColorValueTypes.WARNING },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_NOT_POSSIBLE]:
      { message: t('обмен невозможен'), colorScheme: ThemeColorValueTypes.ERROR },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_PAID]:
      { message: t('оплачен'), colorScheme: ThemeColorValueTypes.SUCCESS },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_PAYMENT_FAILED]:
      { message: t('ошибка оплаты'), colorScheme: ThemeColorValueTypes.ERROR },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_PAYMENT_IN_PROGRESS]:
      { message: t('в процессе оплаты'), colorScheme: ThemeColorValueTypes.INFO },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_REQUEST_SENT]:
      { message: t('отправлен'), colorScheme: ThemeColorValueTypes.INFO },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_TRIPS_FOUND]:
      { message: t('предложения найдены'), colorScheme: ThemeColorValueTypes.INFO },
  [AviaOrderExchangeRequestStatusType.EXCHANGE_TRIPS_SELECTED]:
      { message: t('предложение выбрано'), colorScheme: ThemeColorValueTypes.SUCCESS }
}[status]);
