import config from '@actr/config';
import { AdditionalProductsStatuses, AviaPostBookingProductsTypes } from '@actr/specs';
import { AviaOrderInsurance } from '@actr/stores';
import { getAviaAdditionalProductLogo } from '@actr/utilities';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AccountAviaOrderModelProps } from '../../specs';
import ContainerCard from '../parts/container-card';
import GeneralInfo from '../parts/container-card/parts/general-info';
import ProductCardTicketInfo from '../parts/container-card/parts/product-info';

interface InsuranceCardProps {
  product: AviaOrderInsurance;
}

const InsuranceCard: React.FC<InsuranceCardProps & AccountAviaOrderModelProps> = observer(props => {
  const { product, order } = props;
  const { code, texts, totalPrice, totalStatus } = product;
  const isInactive = [AdditionalProductsStatuses.BOOKED, AdditionalProductsStatuses.REFUNDED].includes(totalStatus);

  const { t } = useOrdersTranslator();

  return (
    <ContainerCard
      generalInfo={(
        <GeneralInfo
          id={code}
          logoUrl={new URL(getAviaAdditionalProductLogo(AviaPostBookingProductsTypes.INSURANCE, code) || '', config.staticURL).href}
          texts={texts}
          status={totalStatus}
          productType={AviaPostBookingProductsTypes.INSURANCE}
        />
      )}
      activePart={(
        <ProductCardTicketInfo
          order={order}
          price={totalPrice}
          status={totalStatus}
          productType={AviaPostBookingProductsTypes.INSURANCE}
          insurancePassengers={product.passengers}
          comment={isInactive
            ? t('Не действует в этом заказе для пассажиров')
            : t('Действует для пассажиров')}
        />
      )}
    />
  );
});

export default InsuranceCard;
