import { useInject } from '@actr/di';
import { orderStatuses, orderTypes } from '@actr/specs';
import {
  OrdersService, OrdersStore,
  TimerStoreListenerDisposer,
  V2OrderStore
} from '@actr/stores';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInstance } from 'react-ioc';

export interface UseOrderExpire {
  /** Признак обновления статуса заказа */
  orderIsUpdating: boolean;
  /** Отмена заказа */
  orderCancellation: () => void;
}

function useOrderExpire(order: V2OrderStore): UseOrderExpire {
  const { orderType, orderInfo } = order;
  const { id } = orderInfo;
  const orders = useInject(OrdersStore);
  const ordersService = useInstance(OrdersService);
  const updateRef = useRef(false);

  const cancelOrderTypeAction = useMemo(() => {
    switch (orderType) {
      case orderTypes.AVIA: return orders.getServerAction('cancelAviaBooking');
      case orderTypes.HOTEL: return orders.getServerAction('cancelHotelBooking');
      case orderTypes.RAIL: return orders.getServerAction('cancelRailwayBooking');
      default: return null;
    }
  }, [orderType]);

  const cancelOrderAction = orders.getServerAction('cancelOrder');
  const updateOrderAction = orders.getServerAction('updateOrder');

  const orderCancellation = useCallback(() => {
    updateRef.current = true;

    ordersService.cancelOrder(order)
      .finally(() => {
        ordersService.updateV2Order(order).finally(() => {
          updateRef.current = false;
        });
      });
  }, [id]);

  useEffect(() => {
    let finishListenerDisposer: TimerStoreListenerDisposer;

    if (!order.isExpired && (order.orderStatus === orderStatuses.BOOKED)) {
      finishListenerDisposer = order.expireTimer.addFinishListener(() => {
        orderCancellation();
      });
    }

    return () => {
      if (finishListenerDisposer) {
        finishListenerDisposer();
      }
      updateOrderAction.cancel();
    };
  }, []);

  return {
    orderIsUpdating: (cancelOrderAction.isPending || updateOrderAction.isPending
      || !!cancelOrderTypeAction?.isPending) && updateRef.current,
    orderCancellation
  };
}

export default useOrderExpire;
