import config from '@actr/config';
import { AdditionalProductsStatuses, AviaPostBookingProductsTypes } from '@actr/specs';
import { AviaOrderAex } from '@actr/stores';
import { getAviaAdditionalProductLogo } from '@actr/utilities';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AccountAviaOrderModelProps } from '../../specs';
import ContainerCard from '../parts/container-card';
import GeneralInfo from '../parts/container-card/parts/general-info';
import ProductCardTicketInfo from '../parts/container-card/parts/product-info';

interface AeroExpressCardProps {
  product: AviaOrderAex;
}

const AeroExpressCard: React.FC<AeroExpressCardProps & AccountAviaOrderModelProps> = observer(props => {
  const { product, order } = props;
  const { id, totalStatus, texts, tickets, status } = product;

  const { t } = useOrdersTranslator();
  const aexStatus = tickets.length ? totalStatus : status;
  const isInactive = [AdditionalProductsStatuses.BOOKED, AdditionalProductsStatuses.REFUNDED].includes(totalStatus);

  function getComment() {
    if (!tickets.length) return '';

    if (isInactive) {
      return `${tickets.length} ${t('билет', { count: tickets.length })}`;
    }

    return t('Скачать билеты');
  }

  return (
    <ContainerCard
      generalInfo={(
        <GeneralInfo
          id={id}
          logoUrl={new URL(getAviaAdditionalProductLogo(AviaPostBookingProductsTypes.AEROEXPRESS) || '', config.staticURL).href}
          texts={texts}
          status={aexStatus}
          productType={AviaPostBookingProductsTypes.AEROEXPRESS}
        />
      )}
      activePart={(
        <ProductCardTicketInfo
          order={order}
          price={product.price}
          status={aexStatus}
          comment={getComment()}
          productType={AviaPostBookingProductsTypes.AEROEXPRESS}
          aexTickets={tickets}
        />
      )}
    />
  );
});

export default AeroExpressCard;
