import { Locales } from '@actr/specs';
import { createTranslatorHook } from '@actr/translator';

import en_US from './en_US.json';
import ru_RU from './ru_RU.json';

const [useOrdersTranslator, ordersTranslator] = createTranslatorHook({
  namespace: 'orders',
  locales: {
    [Locales.en_US]: en_US,
    [Locales.ru_RU]: ru_RU
  }
});

export {
  ordersTranslator
};

export default useOrdersTranslator;
