import { DeviceSizeProps, styleOnMinTablet, styleOnMobile, ThemeColorValueTypes } from '@actr/style';
import { Tag, Text } from '@actr/ui';
import styled from 'styled-components';

export const PaymentHistory = styled.div(({ theme }) => ({
  padding: theme.padding.m,
  border: `1px solid ${theme.colors.border.base.light.default}`,
  borderRadius: theme.borderRadius.m
}));

export const PaymentHistoryItem = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  display: 'grid',
  width: '100%',
  ...styleOnMinTablet(deviceSize, {
    gridTemplateColumns: '1.2fr 1fr 1fr 1fr',
    '& > *': {
      '&:nth-child(3), &:nth-child(4)': {
        marginLeft: 'auto',
      }
    },
    gridColumnGap: theme.margin.xs
  }),
  ...styleOnMobile(deviceSize, {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.margin.xs,
    '& > *': {
      '&:nth-child(4)': {
        marginLeft: 'auto',
      },
      '&:nth-child(2)': {
        gridColumn: '1 / -1'
      }
    }
  })
}));

export const PaymentHistoryItemTitle = styled(Text)<DeviceSizeProps>(({ deviceSize }) => ({
  ...styleOnMobile(deviceSize, {
    gridColumn: '1/-1'
  })
}));

export const PaymentHistoryPrice = styled.div(() => ({}));

export const Delimiter = styled.div(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.colors.border.base.light.default,
  margin: `${theme.margin.xs} 0`
}));

export const PaymentStatusTag = styled(Tag)<{themeColor: ThemeColorValueTypes}>(({ theme, themeColor }) => ({
  height: theme.fontLineHeight.s,
  backgroundColor: theme.colors.surface[themeColor].light.default,
  borderColor: theme.colors.border[themeColor].normal.default,
  color: theme.colors.text[themeColor].normal.default,
  '&, &:focus, &:hover, &:active': {
    backgroundColor: theme.colors.surface[themeColor].light.default,
    borderColor: theme.colors.border[themeColor].normal.default,
    color: theme.colors.text[themeColor].normal.hover,
  },
}));
