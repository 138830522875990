import { styleOnNotLastChild } from '@actr/style';
import styled from 'styled-components';

import PassengerDefault from '../../_common/passenger';
import FlightDefault from './flight';

export const Flight = styled(FlightDefault)(() => ({
}));

export const Passenger = styled(PassengerDefault)(() => ({
}));

export const Passengers = styled.div(() => ({
}));

export const Delimiter = styled.div(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.colors.border.base.light.default,
  margin: `${theme.margin.m} 0`
}));

export const FullLocatorInfo = styled.div(({ theme }) => ({
  ...styleOnNotLastChild({
    borderBottom: `1px solid ${theme.colors.border.base.lighter.default}`,
  })
}));

export const Block = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.border.base.lighter.default}`,
  borderRadius: theme.borderRadius.m,
  ...styleOnNotLastChild({
    marginBottom: theme.margin.x2s
  })
}));
