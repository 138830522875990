import { DialogModal, DialogModalType } from '@actr/ui';
import React from 'react';
import useCommonTranslator from 'Root/locales';

interface CancelBookErrorModalProps {
  open: boolean;
  onClose: () => void;
}

const CancelBookErrorModal: React.FC<CancelBookErrorModalProps> = props => {
  const { open, onClose } = props;
  const { t } = useCommonTranslator();

  function handleClose():void {
    onClose();
  }

  return (
    <DialogModal
      type={DialogModalType.INFO}
      onOk={handleClose}
      open={open}
      hideCloseButton={false}
      backdropCloseModal={true}
      Title={t('Ошибка')}>
      {t('Не получилось отменить заказ.')}
        &ensp;
      {t('Обновить информацию о заказе?')}
    </DialogModal>
  );
};

export default CancelBookErrorModal;
