import { IBaseComponentProps } from '@actr/specs';
import { AviaOrderLocatorInfoSegments, FlightSegmentModel } from '@actr/stores';
import { CompactSegmentInfo, FlightSegmentProps } from '@actr/widgets/src/components/avia/flight';
import xor from 'lodash/xor';
import { observer } from 'mobx-react-lite';
import React, { ComponentType, FC, useMemo } from 'react';

import * as Styled from './style';

export type SegmentWrapperProps = {
  segments: FlightSegmentModel[];
  isRoundTrip: boolean;
  isForwardDirection: boolean;
  isBackDirection: boolean;
}
export type FlightSegmentsInfoProps = {
  segments: AviaOrderLocatorInfoSegments;
  segmentIndexes?: number[];
  SegmentWrapper?: ComponentType<SegmentWrapperProps>;
} & IBaseComponentProps & Pick<FlightSegmentProps, 'isRoute' | 'flightInfo'
    | 'omitTagGroups' | 'disableInfoOpening' | 'isInInfoModal'>

const FlightSegmentsInfo: FC<FlightSegmentsInfoProps> = props => {
  const { segments,
    flightInfo,
    SegmentWrapper,
    segmentIndexes,
    isRoute = true,
    ...rest } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment,react/no-unstable-nested-components
  const InitialWrapper: ComponentType<IBaseComponentProps> = ({ children }) => <>{children}</>;
  const FinalSegmentWrapper = useMemo(() => SegmentWrapper ?? InitialWrapper, []);
  const { segmentsNewFormat, segmentsOldFormat: flightSegments } = segments;

  const { segments_direction } = flightInfo;

  const firstSegment = flightSegments[0];
  const lastSegment = flightSegments[flightSegments.length - 1];
  const isRoundTrip = flightSegments.length === 2 && firstSegment.dep.city.code === lastSegment.arr.city.code;
  const isOneWayTrip = segments_direction.length === 1;

  function renderSegment(finalSegmentIndexes: number[]) {
    const isForwardDirection = isRoundTrip && xor(segments_direction[0], finalSegmentIndexes).length === 0;
    const isBackDirection = isRoundTrip && xor(segments_direction[1], finalSegmentIndexes).length === 0;
    const currentSegments = flightSegments.filter((_, index) => finalSegmentIndexes.includes(index));

    return (
      <FinalSegmentWrapper
        key={finalSegmentIndexes.toString()}
        segments={currentSegments}
        isBackDirection={isBackDirection}
        isForwardDirection={isForwardDirection}
        isRoundTrip={isRoundTrip}>
        <CompactSegmentInfo
          {...rest}
          isRoute={isRoute}
          segments={flightSegments}
          flightInfo={flightInfo}
          isOneWayTrip={isOneWayTrip}
          isBackDirection={isBackDirection}
          isForwardDirection={isForwardDirection}
          isRoundTrip={isRoundTrip}
          segmentIndexes={finalSegmentIndexes}
          isExchanged={segmentsNewFormat[0].isExchanged}
          isRefunded={segmentsNewFormat[0].isRefunded}
        />
      </FinalSegmentWrapper>
    );
  }

  function renderSegments() {
    if (segmentIndexes) {
      return renderSegment(segmentIndexes);
    }

    return (
      <>
        {segments_direction.map(finalSegmentIndexes => (
          renderSegment(finalSegmentIndexes)
        ))}
      </>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Segments>
        {renderSegments()}
      </Styled.Segments>
    </Styled.Wrapper>
  );
};

export default observer(FlightSegmentsInfo);
