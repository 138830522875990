import { styleOnNotLastChild, ThemeColorValueImportance } from '@actr/style';
import { Text } from '@actr/ui';
import styled from 'styled-components';

export const AirportTitle = styled(Text).attrs({
  font: 'paragraph.s',
  color: ThemeColorValueImportance.LIGHTER
})<any>(() => ({
  display: 'flex',
}));

export const CityTitle = styled(Text).attrs({
  font: 'paragraph.m.semi'
})(() => ({
}));

export const Date = styled(Text).attrs({
  font: 'paragraph.s',
  color: ThemeColorValueImportance.LIGHTER
})<any>(() => ({
  display: 'block',
  whiteSpace: 'nowrap',
}));

export const Wrapper = styled.div(() => ({
}));

export const Segments = styled.div(({ theme }) => ({
  ...styleOnNotLastChild({
    paddingBottom: theme.padding.s,
    marginBottom: theme.margin.s,
    borderBottom: `dashed 1px ${theme.colors.border.base.lighter.default}`
  })
}));
