import config from '@actr/config';
import { AdditionalProductsStatuses, AviaPostBookingProductsTypes } from '@actr/specs';
import { AviaOrderAdditionalProducts } from '@actr/stores';
import { getAviaAdditionalProductLogo } from '@actr/utilities';
import useOrdersTranslator from 'Components/account/main-pages/orders/locales';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AccountAviaOrderModelProps } from '../../specs';
import GeneralInfo from '../parts/container-card/parts/general-info';
import ProductCardTicketInfo from '../parts/container-card/parts/product-info';
import * as Styled from './styles';

export interface InsuranceCardProps {
  product: AviaOrderAdditionalProducts;
}

const AviakassaProductCard: React.FC<InsuranceCardProps & AccountAviaOrderModelProps> = observer(props => {
  const { product, order } = props;
  const { id, price, status, type, texts } = product;
  const { t } = useOrdersTranslator();
  const isInactive = [AdditionalProductsStatuses.BOOKED, AdditionalProductsStatuses.REFUNDED,
    AdditionalProductsStatuses.REFUND_AUTHORIZED].includes(status);

  return (
    <Styled.Card
      generalInfo={(
        <GeneralInfo
          id={id}
          logoUrl={new URL(
            getAviaAdditionalProductLogo(AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS, type) || '',
            config.staticURL
          ).href}
          status={status}
          texts={texts}
          productType={AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS}
        />
      )}
      activePart={(
        <ProductCardTicketInfo
          order={order}
          price={price}
          status={status}
          comment={isInactive
            ? t('Не действует в этом заказе')
            : t('За все рейсы в заказе')}
          productType={AviaPostBookingProductsTypes.AVIAKASSA_PRODUCTS}
        />
      )}
    />
  );
});

export default AviakassaProductCard;
