import { AviaPostBookingProductsTypes } from '@actr/specs';
import { observer } from 'mobx-react-lite';
import React from 'react';

import * as Styled from './styles';

export interface AviaPostBookingAdditionalProductLogoProps {
  alt: string;
  href: string;
  productType: AviaPostBookingProductsTypes;
  className?: string;
}

const AviaPostBookingAdditionalProductLogo: React.FC<AviaPostBookingAdditionalProductLogoProps> = observer(props => {
  const { alt, className, href, productType } = props;

  return (
    <Styled.ProductLogoWrapper className={className} type={productType}>
      <Styled.ProductLogo
        src={href}
        alt={alt}
      />
    </Styled.ProductLogoWrapper>
  );
});

export default AviaPostBookingAdditionalProductLogo;
