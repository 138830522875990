import { TimerStore } from '@actr/stores';
import { Sizes } from '@actr/style';
import { FlexWrapper, TagSizes, TagTypes } from '@actr/ui';
import { formatTimerCountdown } from '@actr/utilities';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo } from 'react';

import * as Styled from './style';

const Timer: FC<{ tagTypes?: TagTypes; expireRemain: number; onFinish?: () => void }>
  = ({ tagTypes = TagTypes.TERTIARY, expireRemain, onFinish }) => {
    const timer = useMemo(() => {
      const expireTimer = new TimerStore();
      return expireTimer.start(expireRemain);
    }, []);

    useEffect(() => {
      if (timer.isFinished && onFinish) {
        onFinish();
      }
    }, [timer.isFinished]);

    const { days, hours, minutes } = formatTimerCountdown(timer.timeRemaining);

    return (
      <FlexWrapper rowSpacing={Sizes.X3S} colSpacing={Sizes.X3S}>
        {days && (<Styled.TimeTag tagType={tagTypes} size={TagSizes.M}>{days}</Styled.TimeTag>)}
        <Styled.TimeTag tagType={tagTypes} size={TagSizes.M}>{hours}</Styled.TimeTag>
        <Styled.TimeTag tagType={tagTypes} size={TagSizes.M}>{minutes}</Styled.TimeTag>
      </FlexWrapper>
    );
  };

export default observer(Timer);
