import { IconAdditionalBaggage } from '@actr/icons/additional-baggage';
import { IconPlacePick } from '@actr/icons/place-pick';
import { styleOnNotLastChild } from '@actr/style';
import styled from 'styled-components';

import ContainerCard from '../parts/container-card';

export const Card = styled(ContainerCard)(({ theme }) => ({
  ...styleOnNotLastChild({
    marginBottom: theme.margin.xs
  })
}));

export const BaggageIcon = styled(IconAdditionalBaggage)(({ theme }) => ({
  fontSize: theme.iconSize.x3l,
  maxWidth: '100%'
}));

export const PlaceIcon = styled(IconPlacePick)(({ theme }) => ({
  fontSize: theme.iconSize.x3l,
  maxWidth: '100%'
}));
